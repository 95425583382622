// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blurred-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: black;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border: 2px solid transparent;
  background-clip: padding-box;
  box-shadow: 0 0 5px 3px white;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
  overflow: visable;
  position: relative;
  z-index: 2; /* higher stacking order */
}
.blurred-text:hover {
  cursor: pointer;
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/blurred-text/blurred-text.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,6BAAA;EACA,4BAAA;EACA,6BAAA;EACA,0CAAA,EAKG,0CAAA;EACH,iBAAA;EACA,kBAAA;EACA,UAAA,EAAA,0BAAA;AAJF;AAME;EACE,eAAA;EACA,qBAAA;AAJJ","sourcesContent":[".blurred-text {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  color: black;\n  font-size: 14px;\n  text-align: center;\n  font-weight: bold;\n  border: 2px solid transparent;\n  background-clip: padding-box;\n  box-shadow: 0 0 5px 3px white;\n  background-color: rgba(\n    255,\n    255,\n    255,\n    0.8\n  ); /* Optional: semi-transparent background */\n  overflow: visable;\n  position: relative;\n  z-index: 2; /* higher stacking order */\n\n  &:hover {\n    cursor: pointer;\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
