import { gql } from "@apollo/client";

export const UPDATE_ESTIMATE_MUTATION = gql`
  mutation UpdateEstimate($input: EstimateInput!) {
    updateEstimate(input: $input) {
      _id
      id
      customer_id
      customer_business_then_name
      number
      status
      date
      subtotal
      total
      tax
      balance_due
      line_items {
        _id
        id
        created_at
        item
        name
        cost
        price
        quantity
        product_id
        taxable
        discount_percent
        product_category
        line_discount_percent
        discount_dollars
      }
      ticket_id
      invoice_id
      employee
      created_at
      updated_at
    }
  }
`;
