import "./ticket.styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TicketsContext } from "../../contexts/tickets.context";
import CommentsCard from "../../components/comments-card/comments-card.component";
import ProgressBar from "../../components/progress-bar/progress-bar.component";
import { ReactComponent as ArrowLeft } from "../../assets/icons/greenDoubleLeftArrows.svg";

const getProgress = (statusChanges) => {
  if (!statusChanges.length) return 0;

  const statusMapping = [
    { status: "resolved", progress: 100 },
    { status: "ready for shipment", progress: 75 },
    { status: "received", progress: 50 },
    { status: "new", progress: 25 },
  ];

  for (const { status, progress } of statusMapping) {
    if (
      statusChanges.some((statusChange) =>
        statusChange.new_status.toLowerCase().includes(status)
      )
    ) {
      return progress;
    }
  }

  return 0; // In case none of the statuses are matched
};

const progressBarChildren = (ticket) => {
  const { created_at, status_changes } = ticket;
  const getStatusDate = (status) => {
    return status_changes.find((statusChange) =>
      statusChange.new_status.includes(`${status}`)
    )?.date;
  };
  const received_at = getStatusDate("Received");
  const invoiced_at = getStatusDate("Ready for shipment");
  const resolved_at = getStatusDate("Resolved");

  return [
    { text: "Created", tooltip: `${created_at ? created_at : ""}` },
    {
      text: "Received",
      tooltip: `${received_at ? received_at : ""}`,
    },
    { text: "Invoiced", tooltip: `${invoiced_at ? invoiced_at : ""}` },
    {
      text: "Resolved",
      tooltip: `${resolved_at ? resolved_at : ""}`,
    },
  ];
};

const Ticket = () => {
  const navigate = useNavigate();
  const { fetchTicket } = useContext(TicketsContext);
  const [ticket, setTicket] = useState(null);
  const { id } = useParams();

  const fetchTicketData = async () => {
    const ticketData = await fetchTicket({ _id: id });
    setTicket(ticketData);
  };

  // FETCH TICKET RETURN NULL IF NO TICKET
  useEffect(() => {
    fetchTicketData();
  }, []);
  if (!ticket) return null;

  const { number, comments, status_changes, status } = ticket;
  const progress = getProgress(status_changes);

  const filteredComments = comments.filter((comment) => {
    if (comment.hidden) return null;
    return comment;
  });
  const goBack = () => {
    navigate("/tickets");
  };

  return (
    <div className="ticket index-container">
      <div className="header">
        <div className="redirect-btn">
          <ArrowLeft onClick={goBack} style={{ color: "green" }} />
        </div>
        <h2>
          {number} - {status}
        </h2>
      </div>
      <div className="status-progress-bar">
        <ProgressBar progress={progress}>
          {progressBarChildren(ticket)}
        </ProgressBar>
      </div>
      <div className="ticket-body">
        <CommentsCard
          comments={filteredComments}
          ticket_id={ticket.id}
          refetch={fetchTicketData}
        />
      </div>
    </div>
  );
};

export default Ticket;
