import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { concat } from "@apollo/client/core";

import { UserProvider } from "./contexts/user.context";
import { CustomerProvider } from "./contexts/customer.context";
import { AssetsProvider } from "./contexts/assets.context";
import { TicketsProvider } from "./contexts/tickets.context";
import { InvoicesProvider } from "./contexts/invoices.context";
import { MessagesProvider } from "./contexts/messages.context";
import { PosProvider } from "./contexts/pos.context";
import { ShippingLabelsProvider } from "./contexts/shipping-labels.context";
import { TicketFormProvider } from "./contexts/ticket-form.context";
import { EstimatesProvider } from "./contexts/estimates.context";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const token = process.env.REACT_APP_TOKEN;
const uri = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return "http://localhost:8080/graphql";
    case "production":
      return "https://www.api.techcyclehub.com/graphql";
    case "test":
      return "https://api.test.techcyclehub.com/graphql";
    default:
      return "https://www.api.techcyclehub.com/graphql";
  }
};

const authLink = setContext((_, { headers }) => {
  const userToken = sessionStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
      token: userToken ? userToken : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: uri(),
  credentials: "include",
});

const client = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <MessagesProvider>
        <UserProvider>
          <CustomerProvider>
            <TicketsProvider>
              <AssetsProvider>
                <InvoicesProvider>
                  <PosProvider>
                    <ShippingLabelsProvider>
                      <TicketFormProvider>
                        <EstimatesProvider>
                          <App />
                        </EstimatesProvider>
                      </TicketFormProvider>
                    </ShippingLabelsProvider>
                  </PosProvider>
                </InvoicesProvider>
              </AssetsProvider>
            </TicketsProvider>
          </CustomerProvider>
        </UserProvider>
      </MessagesProvider>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
