import { useState, useEffect } from "react";
import "./asset-search.styles.scss";
import FormInput from "../form-input/form-input.component";

const AssetSearch = ({ assets, results }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [timedSearch, setTimedSearch] = useState("");

  useEffect(() => {
    if (searchResults.length) results(searchResults);
  }, [searchResults]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimedSearch(search);
    }, 700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  useEffect(() => {
    const searchResults = async () => {
      if (timedSearch) {
        const response = assets.filter((asset) =>
          asset.asset_serial.toLowerCase().startsWith(timedSearch.toLowerCase())
        );
        setSearchResults(response);
      }
      if (!timedSearch) results("");
    };

    searchResults();
  }, [timedSearch, assets]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="search-container">
      <FormInput
        label="Search"
        type="search"
        name="search"
        value={search}
        onChange={handleChange}
      />
    </div>
  );
};

export default AssetSearch;
