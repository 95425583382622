import DefaultButton from "../../default/default.button.component";
import "./password.styles.scss";
import { useState } from "react";
import ChangePasswordModal from "../../../change-password-modal/change-password-modal.component";

const PasswordUpdateButton = ({ user }) => {
  const [passwordModal, setPasswordModal] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setPasswordModal(true);
  };
  return (
    <>
      <ChangePasswordModal
        show={passwordModal}
        setShow={setPasswordModal}
        user={user}
      />
      <DefaultButton
        label="Change Password"
        variant="small-text"
        onClick={handleClick}
      />
    </>
  );
};

export default PasswordUpdateButton;
