import { gql } from "@apollo/client";

export const GET_SHIPPING_LABELS_QUERY = gql`
  query GetShippingLabels($contact_id: Int, $customer_id: Int, $sort: String) {
    shippingLabels(
      contact_id: $contact_id
      customer_id: $customer_id
      sort: $sort
    ) {
      tracking_number
      status
      label_url
      contact_id
      customer_id
      account
      created_at
      updated_at
      _id
    }
  }
`;

export const GET_SHIPPING_LABEL_QUERY = gql`
  query GetShippingLabel($_id: String!) {
    shippingLabel(_id: $_id) {
      tracking_number
      status
      label_url
      contact_id
      customer_id
      account
      created_at
      updated_at
      encoded_label
      _id
    }
  }
`;
