import "./shipping-label-small-table.styles.scss";
import ShippingLabelSelectRow from "../shipping-label-select-row/shipping-label-select-row.component";
const ShippingLabelSmallTable = ({ array }) => {
  return (
    <div className="small-table scrollbar">
      {array &&
        array.map((shippingLabel, index) => (
          <ShippingLabelSelectRow
            key={shippingLabel?.tracking_number || index}
            shippingLabel={shippingLabel}
            variant={index % 2 === 0 ? "primary" : "secondary"}
          />
        ))}
    </div>
  );
};

export default ShippingLabelSmallTable;
