// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-item {
  color: #1d0f40;
  font-weight: 500;
  background-color: rgba(142, 198, 63, 0.952);
  background: rgba(142, 198, 63, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 8px 15px 8px 15px;
  border-radius: 10px;
}
.summary-item .label {
  font-size: 0.6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/summaries/summary-item/summary-item.styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,2CAAA;EACA,oCAAA;EACA,gDAAA;EACA,kCAAA;UAAA,0BAAA;EACA,0BAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".summary-item {\n  color: #1d0f40;\n  font-weight: 500;\n  background-color: rgba(142, 198, 63, 0.952);\n  background: rgba(142, 198, 63, 0.25);\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n  backdrop-filter: blur(4px);\n  padding: 8px 15px 8px 15px;\n  border-radius: 10px;\n\n  .label {\n    font-size: 0.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
