import SelectRow from "../select-row/select-row.component";
import "./asset-select-row.styles.scss";
import { useContext } from "react";
import { TicketFormContext } from "../../contexts/ticket-form.context";

const SelectAssetRow = ({ asset, variant }) => {
  const { selectedAsset, setSelectedAsset } = useContext(TicketFormContext);
  const handleSelect = (asset) => {
    setSelectedAsset(asset._id === selectedAsset?._id ? "" : asset);
  };
  return (
    <>
      {asset && (
        <SelectRow
          key={asset?.id || asset._id}
          label={asset.asset_serial}
          variant={variant}
          selected={
            selectedAsset?.asset_serial === asset?.asset_serial ? true : false
          }
          onClick={() => handleSelect(asset)}
        />
      )}
    </>
  );
};

export default SelectAssetRow;
