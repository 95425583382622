import "./shipping-label.styles.scss";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ShippingLabelsContext } from "../../contexts/shipping-labels.context";
import ShippingLabelPdfDownloadButton from "../../components/buttons/download/shipping-label-pdf/shipping-label-pdf.download.button.component";

const ShippingLabel = () => {
  const { id } = useParams();
  const { fetchShippingLabel } = useContext(ShippingLabelsContext);
  const [shippingLabel, setShippingLabel] = useState(null);

  useEffect(() => {
    const fetchShippingLabelData = async () => {
      const shippingLabelData = await fetchShippingLabel(id);
      setShippingLabel(shippingLabelData);
    };
    fetchShippingLabelData();
  }, []);
  if (!shippingLabel) return null;
  return (
    <div className="shipping-label index-container">
      <div className="header">
        <h2>{shippingLabel.tracking_number}</h2>
      </div>
      <div className="btn-options">
        <ShippingLabelPdfDownloadButton shippingLabel={shippingLabel} />
      </div>
    </div>
  );
};

export default ShippingLabel;
