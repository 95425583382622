// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-item-header {
  border-bottom: 1px solid #8dc63f;
}

.line-item-body .line-item {
  margin-top: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.line-item-body .line-item .item {
  font-weight: bold;
}
.line-item-body .line-item .line-item-row {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
}
.line-item-body .line-item .line-item-row .price {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #8dc63f;
  padding-right: 10px;
  width: 20%;
  min-width: 80px;
}
.line-item-body .line-item .line-item-row .name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 8px;
}
.total .summary-item {
  color: #1d0f40;
  font-weight: 500;
  background-color: rgba(142, 198, 63, 0.952);
  background: rgba(142, 198, 63, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 8px 15px 8px 15px;
  border-radius: 10px;
}
.total .summary-item .label {
  font-size: 0.6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/line-items/line-items-card.styles.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;;AAGE;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,iDAAA;AAAJ;AAEI;EACE,iBAAA;AAAN;AAEI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AAAN;AAEM;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,+BAAA;EACA,mBAAA;EACA,UAAA;EACA,eAAA;AAAR;AAGM;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AADR;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,eAAA;AAJF;AAME;EACE,cAAA;EACA,gBAAA;EACA,2CAAA;EACA,oCAAA;EACA,gDAAA;EACA,kCAAA;UAAA,0BAAA;EACA,0BAAA;EACA,mBAAA;AAJJ;AAMI;EACE,iBAAA;AAJN","sourcesContent":[".line-item-header {\n  border-bottom: 1px solid #8dc63f;\n}\n\n.line-item-body {\n  .line-item {\n    margin-top: 10px;\n    padding: 8px;\n    display: flex;\n    flex-direction: column;\n    border: none;\n    border-radius: 5px;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n\n    .item {\n      font-weight: bold;\n    }\n    .line-item-row {\n      display: flex;\n      flex-direction: row;\n      column-gap: 10px;\n      width: 100%;\n\n      .price {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        border-right: 1px solid #8dc63f;\n        padding-right: 10px;\n        width: 20%;\n        min-width: 80px;\n      }\n\n      .name {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n      }\n    }\n  }\n}\n\n.total {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  margin-top: 8px;\n\n  .summary-item {\n    color: #1d0f40;\n    font-weight: 500;\n    background-color: rgba(142, 198, 63, 0.952);\n    background: rgba(142, 198, 63, 0.25);\n    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n    backdrop-filter: blur(4px);\n    padding: 8px 15px 8px 15px;\n    border-radius: 10px;\n\n    .label {\n      font-size: 0.6rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
