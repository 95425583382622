import { gql } from "@apollo/client";

export const GET_POS_QUERY = gql`
  query GetPos($customer_id: Int, $sort: String) {
    pos(customer_id: $customer_id, sort: $sort) {
      _id
      po_number
      po_date
      customer_id
      balance_due
      discount_total
      credits
      total
      invoices {
        _id
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_QUERY = gql`
  query GetPurchaseOrder($_id: String!) {
    purchaseOrder(_id: $_id) {
      _id
      po_number
      po_date
      customer_id
      balance_due
      discount_total
      credits
      total
      budget_code {
        full_budget_code
        campus_name
      }
      invoices {
        _id
        number
        ticket_number
        id
        date
        due_date
        subtotal
        tax
        total
        po_number
        user_type
        line_items {
          item
          price
          quantity
          _id
        }
      }
    }
  }
`;
