import "./line-items-card.styles.scss";
import CardContainer from "../container/card-container.component";
import SummaryItem from "../../summaries/summary-item/summary-item.component";

function formatToUSD(input) {
  // Convert the number to USD currency format
  return input.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

const LineItemsCard = ({ invoice }) => {
  if (!invoice) return null;
  const { line_items: lineItems, total, tax, subtotal } = invoice;
  return (
    <CardContainer>
      <div className="total">
        <SummaryItem label="Tax" content={formatToUSD(+tax)} />
        <SummaryItem label="Subtotal" content={formatToUSD(+subtotal)} />
        <SummaryItem label="Total" content={formatToUSD(+total)} />
      </div>
      <div className="line-item-body">
        {lineItems &&
          lineItems.map((lineItem) => (
            <div className="line-item" key={lineItem.id}>
              <div className="item">{lineItem.item}</div>
              <div className="line-item-row">
                <div className="price">
                  {formatToUSD(+lineItem.price)} x {+lineItem.quantity}
                </div>
                <div className="name">{lineItem.name}</div>
              </div>
            </div>
          ))}
      </div>
    </CardContainer>
  );
};

export default LineItemsCard;
