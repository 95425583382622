import "./new-ticket-sidebar.styles.scss";
import Button from "../button/button.component";
import { useState, useEffect } from "react";
import AssetSidebarTable from "../asset-sidebar-table/asset-sidebar-table.component";
import { ReactComponent as ArrowLeft } from "../../assets/icons/doubleLeftArrows.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/doubleRightArrows.svg";
import ShippingLabelSidebarTable from "../shipping-label-sidebar-table/shipping-label-sidebar-table.component";

const NewTicketSidebar = ({ setSidebar }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSidebar(open);
  }, [open]);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className={`${open ? "open-sidebar" : "closed-sidebar"}`}>
      <div className="sidebar-body scrollbar">
        <AssetSidebarTable />
        <ShippingLabelSidebarTable />
      </div>
      <div className="sidebar-button">
        <Button
          label={open ? <ArrowLeft /> : <ArrowRight />}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default NewTicketSidebar;
