import { useContext, useEffect, useState } from "react";

import "./change-password-modal.styles.scss";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../contexts/user.context";
import FormInput from "../form-input/form-input.component";

const defaultFormFields = {
  password: "",
  password_confirm: "",
};

const ChangePasswordModal = ({ show, setShow = () => null, user }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [errorMessage, setErrorMessage] = useState("");
  const { password, password_confirm } = formFields;
  const { updateUser } = useContext(UserContext);

  const resetModal = () => {
    setFormFields(defaultFormFields);
    setErrorMessage("");
  };

  useEffect(() => {
    if (!show) resetModal();
  }, [show]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!password || !password_confirm)
      return setErrorMessage("Missing Required Field");
    if (password !== password_confirm)
      return setErrorMessage("Passwords don't match");
    if (password.length < 8)
      return setErrorMessage("Password must be at least 8 characters long");
    try {
      await updateUser({ password, _id: user }).then((res) => {
        if (res) {
          setShow(false);
        }
      });
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="md" centered>
      <Modal.Header closeButton>Change Password</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="change-password-modal">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div>
            <FormInput
              label="New Password:"
              type="password"
              name="password"
              value={password}
              onChange={onChangeHandler}
            />
            <FormInput
              label="Confirm Password:"
              type="password"
              name="password_confirm"
              value={password_confirm}
              onChange={onChangeHandler}
            />
          </div>
          <div className="submit-btn">
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
