// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-text-area {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
}
.form-text-area label {
  align-self: flex-start;
  padding: 5px;
  font-weight: bold;
}
.form-text-area textarea {
  padding-right: 0;
  justify-content: end;
  border: none;
  border-radius: 5px;
  width: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.form-text-area textarea:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/form-text-area/form-text-area.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;AACF;AACE;EACE,sBAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAEE;EACE,gBAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,iDAAA;AAAJ;AAEI;EACE,aAAA;AAAN","sourcesContent":[".form-text-area {\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n  width: 100%;\n  justify-content: flex-end;\n\n  label {\n    align-self: flex-start;\n    padding: 5px;\n    font-weight: bold;\n  }\n\n  textarea {\n    padding-right: 0;\n    justify-content: end;\n    border: none;\n    border-radius: 5px;\n    width: auto;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
