import "./invoices.styles.scss";
import { useContext, useEffect, useState } from "react";
import { InvoicesContext } from "../../contexts/invoices.context";
import Filter from "../../components/filter/filter.component";
import LargeTable from "../../components/large-table/large-table.component";

const defaultFormFields = {
  sort: "number",
  direction: "desc",
};

const invoiceFields = [
  { label: "Created", value: "created_at" },
  { label: "Updated", value: "updated_at" },
  { label: "Ticket", value: "ticket_number" },
];

const Invoices = () => {
  const { fetchInvoices } = useContext(InvoicesContext);
  const [invoices, setInvoices] = useState([]);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { sort, direction } = formFields;
  const [results, setResults] = useState("");

  useEffect(() => {
    fetchInvoices(sort, direction).then((res) => {
      setInvoices(res);
    });
  }, [sort, direction]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };
  return (
    <div className="invoices index-container">
      <Filter
        handleChange={handleChange}
        formFields={formFields}
        searchData={invoices}
        searchType={"invoices"}
        results={setResults}
      />
      <LargeTable
        array={results ? results : invoices}
        tableName="Invoices"
        keyValue="_id"
        label="number"
        fields={invoiceFields}
      />
    </div>
  );
};

export default Invoices;
