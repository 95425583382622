// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  padding: 10px 20px 20px 20px;
  min-width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/container/card-container.styles.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,cAAA;AACF","sourcesContent":[".card-container {\n  padding: 10px 20px 20px 20px;\n  min-width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
