// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
  margin-top: 10px;
  color: white;
  background-color: #8dc63f;
  padding: 6px 10px 6px 10px;
  border-radius: 5px;
  border: none;
}
.custom-button:hover {
  box-shadow: 0 0 6px 2px rgb(141, 198, 63);
}

.blue {
  background-color: #1d0f40;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/button/button.styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,yCAAA;AACJ;;AAGA;EACE,yBAAA;EACA,YAAA;AAAF","sourcesContent":[".custom-button {\n  margin-top: 10px;\n  color: white;\n  background-color: #8dc63f;\n  padding: 6px 10px 6px 10px;\n  border-radius: 5px;\n  border: none;\n\n  &:hover {\n    box-shadow: 0 0 6px 2px rgb(141, 198, 63);\n  }\n}\n\n.blue {\n  background-color: #1d0f40;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
