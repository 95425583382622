import "./estimates.styles.scss";
import { useState, useContext, useEffect } from "react";
import { EstimatesContext } from "../../contexts/estimates.context";
import { UserContext } from "../../contexts/user.context";
import LargeTable from "../../components/large-table/large-table.component";
import Filter from "../../components/filter/filter.component";

const defaultFormFields = {
  sort: "number",
  direction: "desc",
};

const estimateFields = [
  { label: "Status", value: "status" },
  { label: "Created", value: "created_at" },
  { label: "Updated", value: "updated_at" },
];

const Estimates = () => {
  const [estimates, setEstimates] = useState([]);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [results, setResults] = useState("");
  const { sort, direction } = formFields;
  const { fetchEstimates } = useContext(EstimatesContext);
  const { currentUser } = useContext(UserContext);
  useEffect(() => {
    fetchEstimates(sort, direction)?.then((estimates) =>
      setEstimates(estimates)
    );
  }, [currentUser, sort, direction]);
  if (!estimates) return null;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };
  return (
    <div className="estimates index-container">
      <Filter
        handleChange={handleChange}
        formFields={formFields}
        searchData={estimates}
        results={setResults}
      />
      <LargeTable
        array={results ? results : estimates}
        keyValue="_id"
        label="number"
        tableName="Estimates"
        fields={estimateFields}
      />
    </div>
  );
};

export default Estimates;
