import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./links-container.styles.scss";
import HamburgerMenu from "../hamburger-menu/hamburger-menu.component";

import { ReactComponent as AdminIcon } from "../../assets/icons/admin.icon.svg";
import { ReactComponent as POStatementImg } from "../../assets/icons/po-statement.svg";
import { ReactComponent as InvoiceImg } from "../../assets/icons/invoice.svg";
import { ReactComponent as ShippingLabelsImg } from "../../assets/icons/shipping.svg";
import { ReactComponent as TicketImg } from "../../assets/icons/ticket.svg";
import { ReactComponent as AssetImg } from "../../assets/icons/assets.svg";
import { ReactComponent as EstimateImg } from "../../assets/icons/estimates.icon.svg";
import { UserContext } from "../../contexts/user.context";
import AccountDropdown from "../account-dropdown/account-dropdown.component";

const LinksContainer = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  const location = window.location.href;

  const goToTickets = () => navigate("/tickets");
  const goToShippingLabels = () => navigate("/shipping-labels");
  const goToInvoices = () => navigate("/invoices");
  const goToAssets = () => navigate("/assets");
  const goToPOs = () => navigate("/pos");
  const goToUsers = () => navigate("/users");
  const goToEstimates = () => navigate("/estimates");

  return (
    <>
      <div className="links-container">
        <div
          className={`nav-img-container ${
            location.includes("/assets") ? "active" : ""
          }`}
          onClick={goToAssets}
        >
          <AssetImg className="nav-img" />
          Assets
        </div>
        <div
          className={`nav-img-container ${
            location.includes("/estimates") ? "active" : ""
          }`}
          onClick={goToEstimates}
        >
          <EstimateImg className="nav-img" />
          Estimates
        </div>
        {currentUser?.role === "admin" && (
          <div
            className={`nav-img-container ${
              location.includes("/invoices") ? "active" : ""
            }`}
            onClick={goToInvoices}
          >
            <InvoiceImg className="nav-img" />
            Invoices
          </div>
        )}
        <div
          className={`nav-img-container ${
            location.includes("/shipping-labels") ? "active" : ""
          }`}
          onClick={goToShippingLabels}
        >
          <ShippingLabelsImg className="nav-img" />
          Labels
        </div>
        {currentUser?.role === "admin" && (
          <div
            className={`nav-img-container ${
              location.includes("/pos") ? "active" : ""
            }`}
            onClick={goToPOs}
          >
            <POStatementImg className="nav-img" />
            PO's
          </div>
        )}
        <div
          className={`nav-img-container ${
            location.includes("/tickets") ? "active" : ""
          }`}
          onClick={goToTickets}
        >
          <TicketImg className="nav-img" />
          Tickets
        </div>
        {currentUser?.role === "admin" && (
          <div
            className={`nav-img-container ${
              location.includes("/users") ? "active" : ""
            }`}
            onClick={goToUsers}
          >
            <AdminIcon className="nav-img" />
            Users
          </div>
        )}
        <AccountDropdown />
      </div>
      <HamburgerMenu />
    </>
  );
};

export default LinksContainer;
