// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
}
.search-container .form-input {
  width: auto;
}
.search-container .form-input label {
  font-size: large;
}
.search-container label {
  padding: 3px;
}
.search-container input {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/shipping-label-search/shipping-label-search.styles.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AACF;AACE;EACE,WAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".search-container {\n  background-color: whitesmoke;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 75%;\n\n  .form-input {\n    width: auto;\n\n    label {\n      font-size: large;\n    }\n  }\n  label {\n    padding: 3px;\n  }\n  input {\n    border-radius: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
