import { useState, useEffect, useRef } from "react";

import "./status-filter.styles.scss";

import { statusOptions } from "../../assets/ticket-options";

const defaultFilter = [
  "Resolved",
  "Resolved - BER",
  "Resolved - Buyout",
  "Resolved - Canceled",
  "Resolved - Denied",
  "Resolved- Shipped",
  "Resolved - Unable To Repair",
];

const StatusFilter = ({ options }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultFilter);
  const [dropdown, setDropdown] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    options(selectedOptions);
  }, [selectedOptions]);

  const handleOptionsSelect = (event) => {
    const value = event.target.value;
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  return (
    <div className="status-filter" ref={menuRef}>
      <label className={`filter-status-label`}>Status Filter</label>
      <div className="dropdown-container" onClick={handleClick}>
        {selectedOptions.length} Selected
      </div>
      <div className={`status-list ${!dropdown ? "hidden" : ""}`} ref={menuRef}>
        {statusOptions.map((option) => (
          <div key={option} className="status-input-container">
            <input
              type="checkbox"
              id={option}
              name={option}
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={handleOptionsSelect}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusFilter;
