// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-content {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.asset-card .card-header {
  font-size: 1.2rem;
  font-weight: 500;
}
.asset-card .row {
  margin-top: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.warranty-card .card-header {
  font-size: 1.2rem;
  font-weight: 500;
}
.warranty-card .row {
  margin-top: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/routes/asset/asset.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,6BAAA;EACA,WAAA;AACF;;AAGE;EACE,iBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,iDAAA;AAAJ;;AAKE;EACE,iBAAA;EACA,gBAAA;AAFJ;AAIE;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,iDAAA;AAFJ","sourcesContent":[".asset-content {\n  padding: 20px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  width: 100%;\n}\n\n.asset-card {\n  .card-header {\n    font-size: 1.2rem;\n    font-weight: 500;\n  }\n  .row {\n    margin-top: 10px;\n    padding: 8px;\n    display: flex;\n    flex-direction: column;\n    border: none;\n    border-radius: 5px;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n  }\n}\n\n.warranty-card {\n  .card-header {\n    font-size: 1.2rem;\n    font-weight: 500;\n  }\n  .row {\n    margin-top: 10px;\n    padding: 8px;\n    display: flex;\n    flex-direction: column;\n    border: none;\n    border-radius: 5px;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
