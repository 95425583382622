import "./asset-small-table.scss";

import AssetSelectRow from "../asset-select-row/asset-select-row.component";

const AssetSmallTable = ({ array, label }) => {
  return (
    <div className="small-table scrollbar">
      {array &&
        array.map((asset, index) => (
          <AssetSelectRow
            key={asset._id}
            asset={asset}
            variant={index % 2 === 0 ? "primary" : "secondary"}
          />
        ))}
    </div>
  );
};

export default AssetSmallTable;
