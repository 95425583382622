import { useContext } from "react";
import { TicketFormContext } from "../../../../contexts/ticket-form.context";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../default/default.button.component";

const CreateTicketNavigateButton = ({ asset }) => {
  const navigate = useNavigate();
  const { setSelectedAsset } = useContext(TicketFormContext);
  const onClick = () => {
    setSelectedAsset(asset);
    navigate(`/tickets/new`);
  };
  return <DefaultButton label="Create Ticket" onClick={onClick} />;
};

export default CreateTicketNavigateButton;
