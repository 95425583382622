function formatToUSD(input) {
  // Convert input to a number
  const number = Number(input);

  // Check if input is not a number or equivalent to 0
  if (isNaN(number) || number === 0) {
    return "$0.00";
  }

  // Convert the number to USD currency format
  const conversion = number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return conversion;
}

module.exports = { formatToUSD };
