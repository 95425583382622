import "./summary-item.styles.scss";

const SummaryItem = ({ label, content }) => {
  return (
    <div className="summary-item">
      <div className="label">{label}</div>
      <div className="content">{content}</div>
    </div>
  );
};

export default SummaryItem;
