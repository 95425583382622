// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #eee;
  border-radius: 15px;
  overflow: visible; /* corrected spelling from 'visable' to 'visible' */
}

.progress-bar {
  position: absolute;
  height: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: width 2s;
}

.progress-bar-children {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/progress-bar/progress-bar.styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA,EAAA,mDAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gDAAA;EACA,0BAAA;EACA,kCAAA;EACA,mBAAA;EACA,2CAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF","sourcesContent":[".progress-bar-container {\n  position: relative;\n  width: 100%;\n  height: 30px;\n  background-color: #eee;\n  border-radius: 15px;\n  overflow: visible; /* corrected spelling from 'visable' to 'visible' */\n}\n\n.progress-bar {\n  position: absolute;\n  height: 100%;\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n  backdrop-filter: blur(4px);\n  -webkit-backdrop-filter: blur(4px);\n  border-radius: 10px;\n  border: 1px solid rgba(255, 255, 255, 0.18);\n  transition: width 2s;\n}\n\n.progress-bar-children {\n  position: relative;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
