import "./large-table-row.styles.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { formatToUSD } from "../../utils/conversion/string-to-usd.conversion";

const VARIANT_CLASSES = {
  primary: "tcs-container-corners",
  secondary: "tcs-container-corners-dark",
};

const format = (field, item) => {
  switch (field?.format) {
    case "currency":
      return formatToUSD(item[field.value]) || "-";
    default:
      return item[field.value] || "-";
  }
};

const LargeTableRow = ({
  item,
  label,
  fields = [],
  variant = "primary",
  allowNavigate = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const handleClick = () => {
    navigate(`${currentPath}/${item?._id}`);
  };
  return (
    <div
      className={`large-table-row ${VARIANT_CLASSES[variant]}`}
      key={item?.id}
      onClick={allowNavigate ? handleClick : null}
    >
      <div className="row-label">
        <label>{item[label]}</label>
      </div>
      <div className="row-body">
        {fields.map((field, index) => (
          <div
            className="row-field"
            key={`${item?.id}-${field.label}` || index}
          >
            <label className="field-label">{field.label}</label>
            <div className="field-value">{format(field, item)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LargeTableRow;
