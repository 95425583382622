import "./shipping-label-pdf.styles.scss";
import DefaultButton from "../../default/default.button.component";
import { useContext } from "react";
import { ShippingLabelsContext } from "../../../../contexts/shipping-labels.context";

const ShippingLabelPdfDownloadButton = ({ shippingLabel }) => {
  // QUICK FIX
  // Changing from Fedex to UPS changed the label format
  const changedDate = new Date("2024-05-24T00:00:00.000Z");
  const { fetchShippingLabel } = useContext(ShippingLabelsContext);
  const handleClick = async () => {
    const label = await fetchShippingLabel(shippingLabel._id);

    // Convert base64 to raw binary data held in a string
    const byteCharacters = atob(label.encoded_label);

    // Convert raw binary string to an array of integers
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convert array of integers into a blob
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const link = document.createElement("a");
    const fileFormat =
      new Date(shippingLabel.created_at) > changedDate ? "gif" : "pdf";
    link.href = window.URL.createObjectURL(blob);
    link.download = `shipping-label.${fileFormat}`;
    link.click();
  };
  return (
    <DefaultButton
      label="Download Label"
      variant="blue"
      onClick={handleClick}
    />
  );
};

export default ShippingLabelPdfDownloadButton;
