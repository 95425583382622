import "./purchase-order.styles.scss";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { POsContext } from "../../contexts/pos.context";
import { UserContext } from "../../contexts/user.context";
import { CustomerContext } from "../../contexts/customer.context";
import { useParams } from "react-router-dom";
import Button from "../../components/button/button.component";
import POTable from "../../components/po-table/po-table.component";
import POHeaderInfo from "../../components/po-header-info/po-header-info.component";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const payNowLink = (purchaseOrder) => {
  if (!purchaseOrder?.invoices?.length) return;
  const findInvoiceIds = purchaseOrder.invoices.map((invoice) => invoice.id);
  const invoiceIds = findInvoiceIds.join("&invoice_ids%5B%5D=");
  return `https://techcyclesolutions.repairshopr.com/my_profile/v2/payments/new?apply=all&invoice_ids%5B%5D=${invoiceIds}`;
};

const PurchaseOrder = () => {
  const { id } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  const { fetchPurchaseOrder, downloadPurchaseOrderPDF } =
    useContext(POsContext);
  const { currentUser } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);

  useEffect(() => {
    if (!purchaseOrder) {
      (async function () {
        setPurchaseOrder(await fetchPurchaseOrder(id));
      })();
    }
  }, [currentUser]);
  if (!purchaseOrder) return null;
  const { po_number, _id } = purchaseOrder;

  const generatePDF = async () => {
    if (!purchaseOrder?._id) return;
    await downloadPurchaseOrderPDF(_id, po_number);
  };

  const payNowOnClick = () => {
    return openInNewTab(payNowLink(purchaseOrder));
  };

  return (
    <div className="purchase-order index-container">
      <Button label="Download PDF" onClick={generatePDF} />
      <div className="document tcs-container">
        <div className="header-row1">
          <div className="sender-info">
            <div>6864 W 153rd St</div>
            <div>Overland Park, KS 66223</div>
            <Link
              to="https://techcyclesolutions.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://techcyclesolutions.com
            </Link>
            <div>(913) 717-7779</div>
          </div>
          <div className="brand">
            <div className="brand-name1">Techcycle</div>
            <div className="brand-name2">Solutions.</div>
          </div>
        </div>
        <div className="header-row2">
          <div className="header-col">
            {customer.id === 29009491 ? (
              <div className="customer-info">
                <div>{purchaseOrder?.budget_code?.campus_name}</div>
                <div>{purchaseOrder?.budget_code?.full_budget_code}</div>
              </div>
            ) : (
              <div className="customer-info">
                <div>{customer.fullname}</div>
                <div>{customer.address}</div>
                <div>
                  {customer.city}, {customer.state} {customer.zip}
                </div>
              </div>
            )}
          </div>
          <div className="header-col">
            <div className="pay-now-btn">
              <Button label="Pay Now" onClick={payNowOnClick} />
            </div>
          </div>
          <div className="header-col">
            <POHeaderInfo
              purchaseOrder={purchaseOrder}
              showDiscount={customer.id === 29009491 ? false : true}
            />
          </div>
        </div>
        <POTable purchaseOrder={purchaseOrder} />
      </div>
    </div>
  );
};

export default PurchaseOrder;
