// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-ticket-container {
  display: flex;
  flex-direction: row;
}
.new-ticket-container .add-margin {
  margin-left: 50vw !important;
}
.new-ticket-container .new-ticket-body {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}
.new-ticket-container .new-ticket-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 80px 0;
  padding: 40px;
}
.new-ticket-container .new-ticket-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.new-ticket-container .new-ticket-form form button {
  margin-top: 20px;
}

@media screen and (max-width: 1100px) {
  .new-ticket-container .add-margin {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 950px) {
  .new-ticket-container {
    padding: 0 20px 80px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/new-ticket/new-ticket.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,4BAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;AAAJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,aAAA;AAAJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACN;AACM;EACE,gBAAA;AACR;;AAKA;EAEI;IACE,yBAAA;EAHJ;AACF;AAOA;EACE;IACE,sBAAA;EALF;AACF","sourcesContent":[".new-ticket-container {\n  display: flex;\n  flex-direction: row;\n\n  .add-margin {\n    margin-left: 50vw !important;\n  }\n\n  .new-ticket-body {\n    display: flex;\n    justify-content: center;\n    margin: auto;\n    width: 100%;\n  }\n  .new-ticket-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: 50px 0 80px 0;\n    padding: 40px;\n    form {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n\n      button {\n        margin-top: 20px;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 1100px) {\n  .new-ticket-container {\n    .add-margin {\n      margin-left: 0 !important;\n    }\n  }\n}\n\n@media screen and (max-width: 950px) {\n  .new-ticket-container {\n    padding: 0 20px 80px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
