import "./po-header-row.styles.scss";

const POHeaderRow = ({ label, value }) => {
  return (
    <div className="po-header-row">
      <div className="col1">{label}</div>
      <div className="col2">{value}</div>
    </div>
  );
};

export default POHeaderRow;
