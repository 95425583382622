const downloadPDF = (base64PDF, documentName) => {
  if (!documentName) throw new Error("Document name is required");
  const byteCharacters = atob(base64PDF);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = documentName;
  link.click();
};

module.exports = { downloadPDF };
