import { gql } from "@apollo/client";

export const GET_ESTIMATES_QUERY = gql`
  query GetEstimates($customer_id: Int, $sort: String) {
    estimates(customer_id: $customer_id, sort: $sort) {
      _id
      id
      customer_id
      customer_business_then_name
      number
      status
      date
      subtotal
      total
      tax
      balance_due
      ticket_id
      invoice_id
      employee
      created_at
      updated_at
    }
  }
`;

export const GET_ESTIMATE_QUERY = gql`
  query GetEstimate($id: Int, $_id: String) {
    estimate(id: $id, _id: $_id) {
      _id
      id
      customer_id
      customer_business_then_name
      number
      status
      date
      subtotal
      total
      tax
      balance_due
      line_items {
        _id
        id
        created_at
        item
        name
        cost
        price
        quantity
        product_id
        taxable
        discount_percent
        product_category
        line_discount_percent
        discount_dollars
      }
      ticket_id
      invoice_id
      employee
      created_at
      updated_at
    }
  }
`;
