import { gql } from "@apollo/client";

export const GET_PENDING_TICKETS = gql`
  query GetPendingTickets($contact_id: String) {
    pendingTickets(contact_id: $contact_id) {
      _id
      asset_serial
      created_at
    }
  }
`;
