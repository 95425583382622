import "./update.styles.scss";
import { useState, useEffect, useContext } from "react";
import FormInput from "../../../form-input/form-input.component";
import RoleSelectFormField from "../../../form-fields/select/role/role.select.form-field.component";
import UserUpdateButton from "../../../buttons/update/user/user.update.button.component";
import { UserContext } from "../../../../contexts/user.context";
import ChangePasswordModal from "../../../change-password-modal/change-password-modal.component";
import PasswordUpdateButton from "../../../buttons/update/password/password.update.button.component";
import UserDeleteButton from "../../../buttons/delete/user/user.delete.button.component";
import StateSelectFormField from "../../../form-fields/select/state/state.select.form-field.component";

const defaultAccountForm = {
  name: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  role: "",
};

const UpdateUserForm = ({ user }) => {
  const { currentUser } = useContext(UserContext);
  const [accountForm, setAccountForm] = useState(defaultAccountForm);
  const [passwordModal, setPasswordModal] = useState(false);
  const { name, email, address1, address2, city, state, zip, role } =
    accountForm;

  useEffect(() => {
    if (user) {
      setAccountForm((prevState) => ({
        ...prevState,
        ...user,
      }));
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAccountForm({ ...accountForm, [name]: value });
  };
  return (
    <form className="update-user-form tcs-container">
      <ChangePasswordModal show={passwordModal} setShow={setPasswordModal} />
      <div className="header">
        <h5>Update User</h5>
        <UserUpdateButton userForm={{ ...accountForm, _id: user._id }} />
      </div>
      <div className="body">
        <FormInput
          label="Name"
          type="text"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <FormInput
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <FormInput
          label="Address"
          type="text"
          name="address1"
          value={address1}
          onChange={handleChange}
        />
        <FormInput
          label="Apt, suite, etc. (optional)"
          type="text"
          name="address2"
          value={address2}
          onChange={handleChange}
        />
        <FormInput
          label="City"
          type="text"
          name="city"
          value={city}
          onChange={handleChange}
        />
        <StateSelectFormField state={state} handleChange={handleChange} />
        <FormInput
          label="Zip"
          type="text"
          name="zip"
          value={zip}
          onChange={handleChange}
        />
        {currentUser.role === "admin" && currentUser._id !== user?._id && (
          <RoleSelectFormField role={role} handleChange={handleChange} />
        )}
        <div className="btn-container">
          <PasswordUpdateButton user={user._id} />
          <UserDeleteButton user={user} />
        </div>
      </div>
    </form>
  );
};

export default UpdateUserForm;
