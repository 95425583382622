// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.create-comment .close-btn {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(0.7px);
  -webkit-backdrop-filter: blur(0.7px);
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 50px;
  font-size: 24px;
  width: 30px;
  height: 30px;
}
.create-comment .close-btn:hover {
  cursor: pointer;
  background-color: rgba(221, 221, 221, 0.65);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}
.create-comment .close-btn:active {
  transform: scale(0.9);
  box-shadow: 2px 1px 2px 1px rgba(29, 15, 64, 0.5);
}
.create-comment button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/create-comment/create-comment.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,qCAAA;EACA,4BAAA;EACA,oCAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACE,eAAA;EACA,2CAAA;EACA,0BAAA;EACA,kCAAA;AACN;AACI;EACE,qBAAA;EACA,iDAAA;AACN;AAGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AADJ","sourcesContent":[".create-comment {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n\n  .close-btn {\n    background: rgba(255, 255, 255, 0.25);\n    backdrop-filter: blur(0.7px);\n    -webkit-backdrop-filter: blur(0.7px);\n    border-radius: 50%;\n    position: absolute;\n    right: 8px;\n    top: 50px;\n    font-size: 24px;\n    width: 30px;\n    height: 30px;\n\n    &:hover {\n      cursor: pointer;\n      background-color: rgba(221, 221, 221, 0.65);\n      backdrop-filter: blur(1px);\n      -webkit-backdrop-filter: blur(1px);\n    }\n    &:active {\n      transform: scale(0.9);\n      box-shadow: 2px 1px 2px 1px rgba(29, 15, 64, 0.5);\n    }\n  }\n\n  button {\n    position: absolute;\n    right: 10px;\n    bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
