import "./po-table.styles.scss";
import POTableRow from "../po-table-row/po-table-row.component";
import { useContext } from "react";
import { CustomerContext } from "../../contexts/customer.context";
import { useEffect } from "react";

const headers = [
  "Batch #",
  "Invoice #",
  "Ticket #",
  "Invoice Date",
  "Due Date",
  "Line Item",
  "Quantity",
  "Line Total",
];

const KELLER_HEADERS = [
  "Batch #",
  "Invoice #",
  "Ticket #",
  "Device Type",
  "Invoice Date",
  "Due Date",
  "Line Item",
  "Quantity",
  "Line Total",
];

function POTable({ purchaseOrder }) {
  const { customer } = useContext(CustomerContext);

  return (
    <div className="po-table">
      <div className="col-headers">
        {(customer.id === 29009491 ? KELLER_HEADERS : headers).map((header) => (
          <div className="col-header" key={header}>
            {header}
          </div>
        ))}
      </div>
      <div className="body">
        {purchaseOrder &&
          purchaseOrder.invoices.map((invoice, invoiceIndex) => {
            return (
              <POTableRow
                invoice={invoice}
                invoiceIndex={invoiceIndex}
                poDate={purchaseOrder.po_date}
                key={invoice.id}
              />
            );
          })}
      </div>
    </div>
  );
}

export default POTable;
