import "./create.styles.scss";
import FormInput from "../../../form-input/form-input.component";
import { useContext, useState } from "react";
import { TicketFormContext } from "../../../../contexts/ticket-form.context";
import FormSelect from "../../../form-select/form-select.component";
import FormTextArea from "../../../form-text-area/form-text-area.component";
import {
  issueTypesDefault,
  MAKE_OPTIONS,
} from "../../../../assets/ticket-options";
import Button from "../../../button/button.component";
import ShippingLabelPdfDownloadButton from "../../../buttons/download/shipping-label-pdf/shipping-label-pdf.download.button.component";
import { UserContext } from "../../../../contexts/user.context";
import { CustomerContext } from "../../../../contexts/customer.context";
import Loader from "../../../loader/loader.component.jsx";

const assetTypeDefault = [
  { value: "Chromebook", label: "Chromebook" },
  { value: "Desktop", label: "Desktop" },
  { value: "Game Console", label: "Game Console" },
  { value: "iPad", label: "iPad" },
  { value: "Laptop", label: "Laptop" },
  { value: "MacBook", label: "MacBook" },
  { value: "Phone", label: "Phone" },
  { value: "Tablet", label: "Tablet" },
];

const USER_TYPE_OPTIONS = [
  { value: "Annex", label: "Annex" },
  { value: "Student / Teacher", label: "Student / Teacher" },
];

const requiredFields = [
  "problem_type",
  "make",
  "model",
  "asset_serial",
  "comment",
  "asset_type",
];

const checkForMissingRequiredFields = (ticketForm) => {
  for (let field of requiredFields) {
    if (!ticketForm[field]) {
      alert(`Please fill out the required field: ${field}`);
      return true;
    }
  }
};

const CreateTicketForm = () => {
  const { ticketForm, setTicketForm, createTicket, selectedShippingLabel } =
    useContext(TicketFormContext);
  const {
    problem_type,
    make,
    model,
    asset_serial,
    comment,
    tracking_number,
    asset_type,
    school,
    user_type,
  } = ticketForm;
  const { currentUser } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTicketForm({ ...ticketForm, [name]: value });
  };
  const handleClick = async (event) => {
    event.preventDefault();
    const missingField = checkForMissingRequiredFields(ticketForm);
    if (missingField) return;

    setLoading(true);

    try {
      console.log("Waiting before submitting...");
      await new Promise(resolve => setTimeout(resolve, 4000));
      await createTicket();
    } catch (error) {
      console.error("Error creating ticket:", error);
    } finally {
      setLoading(false); 
    }
  };
  return (
    <form>
      {loading && <Loader />}
      <FormInput
        label="Tracking Number"
        type="text"
        name="tracking_number"
        value={tracking_number}
        onChange={handleChange}
      />
      <FormSelect
        label="Make"
        options={MAKE_OPTIONS}
        name="make"
        value={make}
        onChange={handleChange}
      />
      <FormInput
        label="Model"
        type="text"
        name="model"
        value={model}
        onChange={handleChange}
      />
      <FormInput
        label="Asset Serial"
        type="text"
        name="asset_serial"
        value={asset_serial}
        onChange={handleChange}
      />
      <FormSelect
        label="Asset Type"
        options={assetTypeDefault}
        name="asset_type"
        value={asset_type}
        onChange={handleChange}
      />
      <FormTextArea
        label="Comment"
        name="comment"
        rows="2"
        value={comment}
        onChange={handleChange}
      />
      <FormSelect
        label="Problem"
        options={issueTypesDefault}
        name="problem_type"
        value={problem_type}
        onChange={handleChange}
      />
      {customer.id === 29009491 && (
        <>
          <FormSelect
            label="School"
            name="school"
            value={ticketForm?.school || ""}
            options={customer.schools.map((school) => ({
              value: school._id,
              label: school.campus_name,
            }))}
            onChange={handleChange}
          />
          <FormSelect
            label="Type"
            name="user_type"
            value={ticketForm?.user_type || ""}
            options={USER_TYPE_OPTIONS}
            onChange={handleChange}
          />
        </>
      )}
      
      <Button
        onClick={handleClick}
        disabled={loading}
        style={{
          backgroundColor: loading ? "grey" : "", // Grey when loading, blue otherwise
          cursor: loading ? "not-allowed" : "pointer",
          opacity: loading ? 0.5 : 1,
        }}
        label={loading ? "Creating..." : "Create"} type="submit"
      />
      {selectedShippingLabel?._id && (
        <ShippingLabelPdfDownloadButton shippingLabel={selectedShippingLabel} />
      )}
    </form>
  );
};

export default CreateTicketForm;
