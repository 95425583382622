import "./user.styles.scss";
import DefaultButton from "../../default/default.button.component";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/user.context";

const UserUpdateButton = ({ userForm }) => {
  const { updateUser } = useContext(UserContext);

  const handleSave = async (event) => {
    event.preventDefault();
    await updateUser({ ...userForm });
  };
  return <DefaultButton label="Save" onClick={handleSave} />;
};

export default UserUpdateButton;
