// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.po-header-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.po-header-row .col1 {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  flex-wrap: nowrap;
  flex: 1 1;
  text-overflow: ellipsis;
}
.po-header-row .col2 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex: 1 1;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/po-header-row/po-header-row.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,uBAAA;AAEJ;AAAE;EACE,aAAA;EACA,iBAAA;EACA,yBAAA;EACA,SAAA;EACA,iBAAA;AAEJ","sourcesContent":[".po-header-row {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  .col1 {\n    display: flex;\n    justify-content: flex-end;\n    white-space: nowrap;\n    flex-wrap: nowrap;\n    flex: 1;\n    text-overflow: ellipsis;\n  }\n  .col2 {\n    display: flex;\n    flex-wrap: nowrap;\n    justify-content: flex-end;\n    flex: 1;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
