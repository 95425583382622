import { gql } from "@apollo/client";

export const BATCH_UPLOAD_PENDING_TICKETS = gql`
  mutation batchUploadPendingTickets(
    $input: [batchUploadPendingTicketsInput]!
  ) {
    batchUploadPendingTickets(input: $input) {
      _id
    }
  }
`;
