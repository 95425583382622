import "./account-dropdown.styles.scss";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../../contexts/user.context";

const AccountDropdown = () => {
  const location = useLocation();
  const { logout } = useContext(UserContext);
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownContainer = useRef();

  // Close dropdowns when location changes
  useEffect(() => {
    if (dropdown) setDropdown(null);
  }, [location]);

  useEffect(() => {
    const handleClick = (e) => {
      if (!dropdownContainer?.current?.contains(e.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  const goToAccount = () => navigate("/account");
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="account-dropdown" ref={dropdownContainer}>
      <div className="image-container" onClick={toggleDropdown}>
        <AccountIcon className="account-icon" />
        Account
      </div>
      <ul className={`dropdown ${dropdown ? "" : "hidden"}`}>
        <li className="dropdown-item" onClick={goToAccount}>
          Account
        </li>
        <li className="dropdown-item" onClick={handleLogout}>
          Logout
        </li>
      </ul>
    </div>
  );
};

export default AccountDropdown;
