import { useState, createContext, useEffect } from "react";

export const MessagesContext = createContext({
  messages: [],
  setMessages: () => null,
  addMessage: () => null,
  removeMessage: () => null,
});

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const addMessage = (text, style) => {
    if (!text || !style) return null;
    setMessages([{ text, style }, ...messages]);
  };

  const removeMessage = (message) => {
    setMessages(messages.filter((m) => m !== message));
  };

  useEffect(() => {
    // This will set up a timer to remove the first message every X seconds.
    const timer = setInterval(() => {
      if (messages.length > 0) {
        setMessages(messages.slice(1));
      }
    }, 5000); // 5000 milliseconds (5 seconds)

    // This will clear the timer when the component unmounts.
    return () => clearInterval(timer);
  }, [messages]);

  const value = {
    messages,
    setMessages,
    addMessage,
    removeMessage,
  };

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
};
