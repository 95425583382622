import { gql } from "@apollo/client";

export const SEND_EMAIL = gql`
  mutation SendEmail(
    $subject: String!
    $from: String!
    $emails: [String!]
    $message: String!
  ) {
    sendEmail(
      subject: $subject
      from: $from
      emails: $emails
      message: $message
    )
  }
`;
