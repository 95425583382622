// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-sidebar-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 5px 0 5px;
  background: #ffffff;
}
.asset-sidebar-table .table {
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.asset-sidebar-table .table .table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
  width: 100%;
}
@media screen and (max-width: 700px) {
  .asset-sidebar-table .table .table-header .search-container .form-input {
    width: 100% !important;
  }
  .asset-sidebar-table .table .table-header .search-container .form-input label {
    padding: 3px;
  }
  .asset-sidebar-table .table .table-header .search-container .form-input input {
    border-radius: 8px;
    width: 100% !important;
  }
}
.asset-sidebar-table .table .table-header .table-label {
  display: flex;
  justify-content: flex-start;
  margin: auto;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/asset-sidebar-table/asset-sidebar-table.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AACN;AACM;EAEI;IACE,sBAAA;EAAV;EACU;IACE,YAAA;EACZ;EACU;IACE,kBAAA;IACA,sBAAA;EACZ;AACF;AAIM;EACE,aAAA;EACA,2BAAA;EACA,YAAA;EACA,iBAAA;AAFR","sourcesContent":[".asset-sidebar-table {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  padding: 5px 5px 0 5px;\n  background: #ffffff;\n\n  .table {\n    background: #ffffff;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: center;\n\n    .table-header {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      align-items: center;\n      font-size: x-large;\n      width: 100%;\n\n      @media screen and (max-width: 700px) {\n        .search-container {\n          .form-input {\n            width: 100% !important;\n            label {\n              padding: 3px;\n            }\n            input {\n              border-radius: 8px;\n              width: 100% !important;\n            }\n          }\n        }\n      }\n\n      .table-label {\n        display: flex;\n        justify-content: flex-start;\n        margin: auto;\n        font-weight: bold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
