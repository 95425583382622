// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.estimate-status-select-form-field {
  display: flex;
  justify-content: center;
  align-items: center;
}
.estimate-status-select-form-field select {
  padding: 0 8px 0 8px;
  border: none;
  border-radius: 5px;
  width: auto;
  height: 42px;
  font-size: auto;
  font-weight: 500;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.estimate-status-select-form-field select:focus {
  outline: none;
}
.estimate-status-select-form-field select .Approved {
  color: #8dc63f;
}
.estimate-status-select-form-field select .Declined {
  color: #ff0000;
}
.estimate-status-select-form-field option {
  font-size: 16px;
  font-weight: 500;
  background-color: whitesmoke;
}`, "",{"version":3,"sources":["webpack://./src/components/form-fields/select/estimate-status/estimate-status.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iDAAA;AACJ;AAAI;EACE,aAAA;AAEN;AACI;EACE,cAAA;AACN;AACI;EACE,cAAA;AACN;AAGE;EACE,eAAA;EACA,gBAAA;EACA,4BAAA;AADJ","sourcesContent":[".estimate-status-select-form-field {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  select {\n    padding: 0 8px 0 8px;\n    border: none;\n    border-radius: 5px;\n    width: auto;\n    height: 42px;\n    font-size: auto;\n    font-weight: 500;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n    &:focus {\n      outline: none;\n    }\n\n    .Approved {\n      color: #8dc63f;\n    }\n    .Declined {\n      color: #ff0000;\n    }\n  }\n\n  option {\n    font-size: 16px;\n    font-weight: 500;\n    background-color: whitesmoke;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
