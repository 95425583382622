import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { UserContext } from "./contexts/user.context";

import RoleAccess from "./utils/AAA/role-access";
import Account from "./routes/account/account.component";
import Assets from "./routes/assets/assets.component";
import Asset from "./routes/asset/asset.component";
import CreateUser from "./routes/create-user/create-user.component";
import Login from "./routes/login/login.component";
import Navbar from "./routes/navbar/navbar.component";
import ShippingLabel from "./routes/shipping-label/shipping-label.component";
import ShippingLabels from "./routes/shipping-labels/shipping-labels.component";
import Tickets from "./routes/tickets/tickets.component";
import Ticket from "./routes/ticket/ticket.component";
import PendingTickets from "./routes/pending-tickets/pending-tickets.component";
import POs from "./routes/pos/pos.component";
import PurchaseOrder from "./routes/purchase-order/purchase-order.component";
import Invoices from "./routes/invoices/invoices.component";
import Invoice from "./routes/invoice/invoice.component";
import User from "./routes/user/user.component";
import Users from "./routes/users/users.component";
import NewTicket from "./routes/new-ticket/new-ticket.component";
import Estimates from "./routes/estimates/estimates.component";
import Estimate from "./routes/estimate/estimate.component";

function App() {
  const { currentUser } = useContext(UserContext);

  return (
    <Routes>
      {!currentUser ? (
        <Route path="/" element={<Navbar />}>
          <Route path="/login" element={<Login />} />
        </Route>
      ) : (
        <Route path="/" element={<Navbar />}>
          <Route element={<RoleAccess roles={["user", "admin"]} />}>
            <Route path="/account" element={<Account />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="/assets/:id" element={<Asset />} />
            <Route path="/estimates" element={<Estimates />} />
            <Route path="/estimates/:id" element={<Estimate />} />
            <Route path="/shipping-labels" element={<ShippingLabels />} />
            <Route path="/shipping-labels/:id" element={<ShippingLabel />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/tickets/new" element={<NewTicket />} />
            <Route path="/tickets/pending" element={<PendingTickets />} />
            <Route path="/tickets/:id" element={<Ticket />} />
          </Route>
          <Route element={<RoleAccess roles={["admin"]} />}>
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/invoices/:id" element={<Invoice />} />
            <Route path="/pos" element={<POs />} />
            <Route path="/pos/:id" element={<PurchaseOrder />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/users/:id" element={<User />} />
          </Route>
        </Route>
      )}
    </Routes>
  );
}

export default App;
