// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.per-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 80px 0;
  margin: auto;
  gap: 20px;
  width: 1200px;
}

@media screen and (max-width: 1280px) {
  .per-view-container {
    width: 900px;
  }
}
@media screen and (min-width: 1280px) {
  .per-view-container {
    width: 1200px;
  }
}
@media screen and (max-width: 950px) {
  .per-view-container {
    width: auto;
    padding: 0 20px 80px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/per-view/container/per-view-container.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,SAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,YAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;EAAF;AACF;AAGA;EACE;IACE,WAAA;IACA,yBAAA;EADF;AACF","sourcesContent":[".per-view-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 20px 0 80px 0;\n  margin: auto;\n  gap: 20px;\n  width: 1200px;\n}\n\n@media screen and (max-width: 1280px) {\n  .per-view-container {\n    width: 900px;\n  }\n}\n\n@media screen and (min-width: 1280px) {\n  .per-view-container {\n    width: 1200px;\n  }\n}\n\n@media screen and (max-width: 950px) {\n  .per-view-container {\n    width: auto;\n    padding: 0 20px 80px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
