import { gql } from "@apollo/client";

export const UPLOAD_ASSETS = gql`
  mutation UploadAssets($assets: [AssetInput!]!) {
    uploadAssets(assets: $assets) {
      asset_serial
      make
      model
      service_tag
    }
  }
`;
