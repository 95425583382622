import "./shipping-label-select-row.styles.scss";
import SelectRow from "../select-row/select-row.component";
import { useContext } from "react";
import { TicketFormContext } from "../../contexts/ticket-form.context";

const ShippingLabelSelectRow = ({ shippingLabel, variant }) => {
  const { selectedShippingLabel, setSelectedShippingLabel } =
    useContext(TicketFormContext);

  const handleSelect = (shippingLabel) => {
    setSelectedShippingLabel(
      shippingLabel.tracking_number === selectedShippingLabel?.tracking_number
        ? ""
        : shippingLabel
    );
  };

  return (
    <>
      {shippingLabel && (
        <SelectRow
          key={shippingLabel?.id || shippingLabel._id}
          label={shippingLabel.tracking_number}
          variant={variant}
          selected={
            selectedShippingLabel?.tracking_number ===
            shippingLabel?.tracking_number
              ? true
              : false
          }
          onClick={() => handleSelect(shippingLabel)}
        />
      )}
    </>
  );
};

export default ShippingLabelSelectRow;
