import "./shipping-label-sidebar-table.styles.scss";
import ShippingLabelSearch from "../shipping-label-search/shipping-label-search.component";
import { useState, useContext, useEffect } from "react";
import { ShippingLabelsContext } from "../../contexts/shipping-labels.context";
import ShippingLabelSmallTable from "../shipping-label-small-table/shipping-label-small-table.component";
import ShippingLabelPdfDownloadButton from "../buttons/download/shipping-label-pdf/shipping-label-pdf.download.button.component";
import { TicketFormContext } from "../../contexts/ticket-form.context";
import ShippingLabelCreateButton from "../buttons/create/shipping-label/shipping-label.create.button.component";

const ShippingLabelSidebarTable = () => {
  const { fetchShippingLabels } = useContext(ShippingLabelsContext);
  const { selectedShippingLabel } = useContext(TicketFormContext);
  const [shippingLabels, setShippingLabels] = useState([]);
  const [results, setResults] = useState("");

  useEffect(() => {
    fetchShippingLabels().then((response) => {
      setShippingLabels(response);
    });
  }, []);

  const handleNewLabel = (newLabel) => {
    setShippingLabels([newLabel, ...shippingLabels]);
  };

  return (
    <div className="shipping-label-sidebar-table tcs-container">
      <div className="table">
        <div className="table-header">
          <label className="table-label">Shipping Labels</label>
          <ShippingLabelSearch results={setResults} />
        </div>
        <div className="btn-options">
          {selectedShippingLabel?._id && (
            <ShippingLabelPdfDownloadButton
              shippingLabel={selectedShippingLabel}
            />
          )}
          <ShippingLabelCreateButton addLabel={handleNewLabel} />
        </div>
        <ShippingLabelSmallTable array={results ? results : shippingLabels} />
      </div>
    </div>
  );
};

export default ShippingLabelSidebarTable;
