import "./messages.styles.scss";
import { useContext } from "react";
import { MessagesContext } from "../../contexts/messages.context";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const Messages = () => {
  const { messages, setMessages } = useContext(MessagesContext);
  if (!messages.length) return null;

  const handleCloseMessages = () => {
    setMessages([]);
  };
  return (
    <div className="messages">
      <div className="messages-container">
        <div className="messages-scroll-container">
          {messages.map(({ text, style }, index) => (
            <div key={`${text}-${index}`} className={`message ${style}`}>
              {text}
            </div>
          ))}
        </div>
        <div className="close-btn" onClick={handleCloseMessages}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default Messages;
