import "./role.styles.scss";
import FormSelect from "../../../form-select/form-select.component";

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "Limited User" },
];

const RoleSelectFormField = ({ role, handleChange }) => {
  return (
    <FormSelect
      label="Role"
      options={roleOptions}
      name="role"
      value={role}
      onChange={handleChange}
    />
  );
};

export default RoleSelectFormField;
