import "./shipping-label-search.styles.scss";
import { useState, useContext, useEffect } from "react";
import FormInput from "../form-input/form-input.component";
import { ShippingLabelsContext } from "../../contexts/shipping-labels.context";

const ShippingLabelSearch = ({ results }) => {
  const { shippingLabels } = useContext(ShippingLabelsContext);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [timedSearch, setTimedSearch] = useState("");

  useEffect(() => {
    if (searchResults.length) results(searchResults);
  }, [searchResults]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimedSearch(search);
    }, 700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  useEffect(() => {
    const searchResults = async () => {
      if (timedSearch) {
        const response = shippingLabels.filter((shippingLabel) =>
          shippingLabel.tracking_number
            .toLowerCase()
            .startsWith(timedSearch.toLowerCase())
        );
        setSearchResults(response);
      }
      if (!timedSearch) results("");
    };

    searchResults();
  }, [timedSearch, shippingLabels]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="search-container">
      <FormInput
        label="Search"
        type="search"
        name="search"
        value={search}
        onChange={handleChange}
      />
    </div>
  );
};

export default ShippingLabelSearch;
