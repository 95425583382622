// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.po-cell {
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.po-cell .cell-label {
  display: none;
}
@media screen and (max-width: 500px) {
  .po-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    width: 100%;
    height: 100%;
    padding: 5px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke;
  }
  .cell-label {
    color: #1d0f40;
    display: flex !important;
    border-bottom: 1px solid #8dc63f;
  }
  .cell-value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/po-cell/po-cell.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,uBAAA;AACF;AACE;EACE,aAAA;AACJ;AAMA;EACE;IACE,aAAA;IACA,sBAAA;IACA,uBAAA;IACA,mBAAA;IACA,gBAAA;IACA,WAAA;IACA,YAAA;IACA,YAAA;IACA,YAAA;IACA,kBAAA;IACA,iDAAA;IACA,4BAAA;EAJF;EAOA;IACE,cAAA;IACA,wBAAA;IACA,gCAAA;EALF;EAQA;IACE,aAAA;IACA,uBAAA;IACA,mBAAA;IACA,kBAAA;IACA,iBAAA;EANF;AACF","sourcesContent":[".po-cell {\n  display: flex;\n  flex: 1;\n  justify-content: center;\n\n  .cell-label {\n    display: none;\n  }\n\n  .cell-value {\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .po-cell {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    min-width: 100px;\n    width: 100%;\n    height: 100%;\n    padding: 5px;\n    border: none;\n    border-radius: 5px;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n    background-color: whitesmoke;\n  }\n\n  .cell-label {\n    color: #1d0f40;\n    display: flex !important;\n    border-bottom: 1px solid #8dc63f;\n  }\n\n  .cell-value {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
