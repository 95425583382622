// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hamburger-menu {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  text-shadow: 0 0 10px white;
}
.hamburger-menu .hamburger-menu-icon:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
}
.hamburger-menu .dropdown {
  position: absolute;
  background-color: rgba(142, 198, 63, 0.952);
  background: rgba(142, 198, 63, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 15px;
  border-radius: 10px;
  top: 70px;
}
.hamburger-menu .dropdown .dropdown-item {
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  cursor: pointer;
}
.hamburger-menu .dropdown .dropdown-item:hover {
  background-color: #8dc63f;
}

@media screen and (min-width: 1200px) {
  .hamburger-menu {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/hamburger-menu/hamburger-menu.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AAEI;EACE,eAAA;EACA,uBAAA;EACA,kBAAA;AAAN;AAIE;EACE,kBAAA;EACA,2CAAA;EACA,oCAAA;EACA,gDAAA;EACA,kCAAA;UAAA,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAII;EACE,kBAAA;EACA,0BAAA;EACA,iBAAA;EAEA,eAAA;AAHN;AAKM;EACE,yBAAA;AAHR;;AASA;EACE;IACE,aAAA;EANF;AACF","sourcesContent":[".hamburger-menu {\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 30px;\n  text-shadow: 0 0 10px white;\n\n  .hamburger-menu-icon {\n    &:hover {\n      cursor: pointer;\n      background-color: white;\n      border-radius: 5px;\n    }\n  }\n\n  .dropdown {\n    position: absolute;\n    background-color: rgba(142, 198, 63, 0.952);\n    background: rgba(142, 198, 63, 0.25);\n    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n    backdrop-filter: blur(4px);\n    padding: 15px;\n    border-radius: 10px;\n    top: 70px;\n\n    .dropdown-item {\n      border-radius: 3px;\n      padding: 5px 10px 5px 10px;\n      font-weight: bold;\n\n      cursor: pointer;\n\n      &:hover {\n        background-color: #8dc63f;\n      }\n    }\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .hamburger-menu {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
