import "./create.styles.scss";
import { useState, useContext } from "react";
import FormInput from "../../../form-input/form-input.component";
import RoleSelectFormField from "../../../form-fields/select/role/role.select.form-field.component";
import { UserContext } from "../../../../contexts/user.context";
import UserCreateButton from "../../../buttons/create/user/user.create.button.component";
import StateSelectFormField from "../../../form-fields/select/state/state.select.form-field.component";

const defaultAccountForm = {
  name: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  role: "",
  password: "",
};

const requiredFields = [
  "name",
  "email",
  "address1",
  "city",
  "state",
  "zip",
  "role",
];

const CreateUserForm = () => {
  const { currentUser } = useContext(UserContext);
  const [accountForm, setAccountForm] = useState(defaultAccountForm);
  const { name, email, address1, address2, city, state, zip, role, password } =
    accountForm;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAccountForm({ ...accountForm, [name]: value });
  };
  return (
    <form className="update-user-form tcs-container">
      <div className="header">
        <h5>Create User</h5>

        <UserCreateButton
          userForm={{ ...accountForm }}
          setUserForm={setAccountForm}
          requiredFields={requiredFields}
        />
      </div>
      <div className="body">
        <FormInput
          label="Name"
          type="text"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <FormInput
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <FormInput
          label="Address"
          type="text"
          name="address1"
          value={address1}
          onChange={handleChange}
        />
        <FormInput
          label="Apt, suite, etc. (optional)"
          type="text"
          name="address2"
          value={address2}
          onChange={handleChange}
        />
        <FormInput
          label="City"
          type="text"
          name="city"
          value={city}
          onChange={handleChange}
        />
        <StateSelectFormField state={state} handleChange={handleChange} />
        <FormInput
          label="Zip"
          type="text"
          name="zip"
          value={zip}
          onChange={handleChange}
        />
        <FormInput
          label="Password"
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
        />
        {currentUser.role === "admin" && (
          <RoleSelectFormField role={role} handleChange={handleChange} />
        )}
      </div>
    </form>
  );
};

export default CreateUserForm;
