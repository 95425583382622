import { gql } from "@apollo/client";

export const CREATE_SHIPPING_LABEL_MUTATION = gql`
  mutation CreateShippingLabel {
    createShippingLabel {
      _id
      tracking_number
      status
      label_url
      contact_id
      customer_id
      account
      created_at
      updated_at
    }
  }
`;
