import "./comments-card.styles.scss";
import CreateComment from "../create-comment/create-comment.component";

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const CommentsCard = ({ comments, ticket_id, refetch }) => {
  return (
    <div className="comments tcs-container">
      <div className="header">
        <h3>Comments</h3>
      </div>
      <CreateComment ticket_id={ticket_id} refetch={refetch} />
      <div className="body">
        {comments &&
          comments.map((comment) => (
            <div className="comment" key={comment.id}>
              <div className="subject">{comment.subject}</div>
              <div className="comment-row">
                <div className="date-time">
                  {formatDate(comment.updated_at)}
                </div>
                <div className="comment-body">
                  <p dangerouslySetInnerHTML={{ __html: comment.body }} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CommentsCard;
