import { useState } from "react";

import "./contact-us-button.styles.scss";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SEND_EMAIL } from "../../api/mutations/email.mutations";
import { useMutation } from "@apollo/client";
import validateEmail from "../../utils/validateEmail.utils";
import FormInput from "../form-input/form-input.component";
import FormTextArea from "../form-text-area/form-text-area.component";

const defaultFormFields = {
  email: "",
  subject: "",
  message: "",
};

const ContactUsButton = () => {
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [errorMessage, setErrorMessage] = useState("");
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, subject, message } = formFields;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setErrorMessage("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!email || !message || !subject)
      return setErrorMessage("All fields are required");

    if (!validateEmail(email))
      return setErrorMessage("Please enter a valid email address");

    await sendEmail({
      variables: {
        subject,
        from: email,
        emails: ["sales@techcyclesolutions.com"],
        message: `CONTACT: ${email}\n\nMESSAGE: ${message}`,
      },
    });
    setShow(false);
    setFormFields(defaultFormFields);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Contact Us
      </Button>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="contact-modal-body" onSubmit={handleSubmit}>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="contact-input-container">
              <FormInput
                label="Your Email:"
                type="email"
                id="contact-email"
                autoComplete="off"
                required
                name="email"
                value={email}
                onChange={handleChange}
              />
              <FormInput
                label="Subject:"
                type="text"
                id="contact-subject"
                autoComplete="off"
                name="subject"
                value={subject}
                onChange={handleChange}
              />
              <FormTextArea label="Message" name="message" rows="2" />
            </div>

            <div className="submit-btn">
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Send Email
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactUsButton;
