// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paid {
  color: #8dc63f;
}

.unpaid {
  color: #ff0000 !important;
}`, "",{"version":3,"sources":["webpack://./src/routes/invoice/invoice.styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".paid {\n  color: #8dc63f;\n}\n\n.unpaid {\n  color: #ff0000 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
