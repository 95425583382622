const searchDefault = async (timedSearch, data, results) => {
  if (timedSearch) {
    let response = data.filter((item) => {
      const foundResults = Object.entries(item).filter(([key, value]) => {
        if (typeof value === "number") value = value.toString();
        if (typeof value === "string") {
          return value.toLowerCase().startsWith(timedSearch.toLowerCase());
        }
        return false;
      });
      return foundResults.length ? foundResults : false;
    });
    results(response);
  }
};

module.exports = { searchDefault };
