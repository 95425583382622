import "./filter.styles.scss";
import Search from "../search/search.component";
import FormSelect from "../form-select/form-select.component";
import StatusFilter from "../status-filter/status-filter.component";

const defaultSortOptions = [
  { label: "Created", value: "created_at" },
  { label: "Number", value: "number" },
  { label: "Updated", value: "updated_at" },
];
const poSortOptions = [
  { label: "Created", value: "po_date" },
  { label: "Number", value: "po_number" },
];
const shippingLabelSortOptions = [
  { label: "Created", value: "created_at" },
  { label: "Tracking", value: "tracking_number" },
];
const userSortOptions = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Role", value: "role" },
];
const sortOptions = (sortType) => {
  switch (sortType) {
    case "po":
      return poSortOptions;
    case "shippingLabel":
      return shippingLabelSortOptions;
    case "user":
      return userSortOptions;
    default:
      return defaultSortOptions;
  }
};

const sortDirections = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
];

const Filter = ({
  sortType,
  handleChange,
  formFields,
  searchData,
  searchType,
  results,
  statusFilter,
}) => {
  const { sort, direction } = formFields;

  return (
    <div className="filter tcs-container">
      <div className="filter-item">
        <Search
          data={searchData}
          results={results}
          searchType={searchType}
          type={searchType}
        />
      </div>
      <div className="filter-item">
        <FormSelect
          label="Sort"
          options={sortOptions(sortType)}
          value={sort}
          name="sort"
          onChange={handleChange}
        />
        <FormSelect
          label="Direction"
          options={sortDirections}
          value={direction}
          name="direction"
          onChange={handleChange}
        />
      </div>
      {statusFilter && (
        <div className="filter-item">
          <StatusFilter options={statusFilter} />
        </div>
      )}
    </div>
  );
};

export default Filter;
