// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-table {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  padding: 10px 5px 10px 5px;
  row-gap: 10px;
  height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/asset-small-table/asset-small-table.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,0BAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".small-table {\n  display: flex;\n  flex-direction: column;\n  background-color: whitesmoke;\n  padding: 10px 5px 10px 5px;\n  row-gap: 10px;\n  height: 35vh;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
