import "./form-select.styles.scss";

const FormSelect = ({ label, options, ...otherProps }) => {
  return (
    <div className="form-select-container">
      <label>{label}</label>
      <select {...otherProps}>
        <option disabled value=""></option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
