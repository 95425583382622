import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { Outlet, Navigate } from "react-router-dom";

const RoleAccess = ({ roles = [] }) => {
  const { currentUser } = useContext(UserContext);
  return !roles.length || roles.includes(currentUser?.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/404" replace />
  );
};

export default RoleAccess;
