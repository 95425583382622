import "./button.styles.scss";

const Button = ({ label, variant, ...otherProps }) => {
  return (
    <button {...otherProps} className={`custom-button ${variant}`}>
      {label}
    </button>
  );
};

export default Button;
