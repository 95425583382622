import { createContext, useContext, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ESTIMATES_QUERY,
  GET_ESTIMATE_QUERY,
} from "../api/queries/estimates.queries";
import { UPDATE_ESTIMATE_MUTATION } from "../api/mutations/estimates.mutations";
import { MessagesContext } from "./messages.context";
import { UserContext } from "./user.context";

export const EstimatesContext = createContext({
  estimates: [],
  setEstimates: () => null,
  fetchEstimates: () => null,
  fetchEstimate: () => null,
  updateEstimate: () => null,
});

export const EstimatesProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(UserContext);
  const [estimates, setEstimates] = useState([]);
  const [getEstimates] = useLazyQuery(GET_ESTIMATES_QUERY);
  const [getEstimate] = useLazyQuery(GET_ESTIMATE_QUERY);
  const [updateEstimateMutation] = useMutation(UPDATE_ESTIMATE_MUTATION);

  const fetchEstimates = async (sort, direction) => {
    try {
      const response = await getEstimates({
        variables: {
          customer_id: currentUser.customer_id,
          sort: `${direction === "asc" ? "" : "-"}${sort}`,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response.error)
        throw new Error("Error fetching estimates");
      return response.data.estimates;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const fetchEstimate = async ({ id, _id }) => {
    try {
      const response = await getEstimate({
        variables: {
          id,
          _id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response.error)
        throw new Error("Error fetching estimate");
      return response.data.estimate;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const updateEstimate = async ({ _id, ...input }) => {
    try {
      const response = await updateEstimateMutation({
        variables: {
          input: {
            _id,
            ...input,
          },
        },
        fetchPolicy: "no-cache",
      });
      if (!response || response.error)
        throw new Error("Error updating estimate");
      addMessage("Estimate updated successfully", "success");
      return response.data.updateEstimate;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    estimates,
    setEstimates,
    fetchEstimates,
    fetchEstimate,
    updateEstimate,
  };
  return (
    <EstimatesContext.Provider value={value}>
      {children}
    </EstimatesContext.Provider>
  );
};
