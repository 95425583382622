import "./invoice.styles.scss";
import PerViewContainer from "../../components/per-view/container/per-view-container.component";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InvoicesContext } from "../../contexts/invoices.context";
import { TicketsContext } from "../../contexts/tickets.context";
import LineItemsCard from "../../components/cards/line-items/line-items-card.component";
import Button from "../../components/button/button.component";
import CommentsCard from "../../components/comments-card/comments-card.component";

const Invoice = () => {
  const { id } = useParams();
  const { downloadInvoicePDF } = useContext(InvoicesContext);
  const [invoice, setInvoice] = useState(null);
  const [ticket, setTicket] = useState(null);
  const { fetchInvoice } = useContext(InvoicesContext);
  const { fetchTicket } = useContext(TicketsContext);

  const fetchTicketData = async () => {
    if (!invoice) return;
    const ticketData = await fetchTicket({ id: invoice?.ticket_id });
    setTicket(ticketData);
  };

  useEffect(() => {
    if (!invoice) {
      const getInvoice = async () => {
        try {
          const invoice = await fetchInvoice(id);
          setInvoice(invoice);
        } catch (error) {
          throw new Error("error fetching invoice");
        }
      };
      getInvoice();
    }
    if (invoice) {
      fetchTicketData({ id: invoice.ticket_id });
    }
  }, [invoice]);
  if (!invoice) return null;
  const filteredComments = ticket?.comments?.filter((comment) => {
    if (comment.hidden) return null;
    return comment;
  });
  const generatePDF = async () => {
    if (!invoice?._id) return;
    await downloadInvoicePDF(invoice._id, invoice.number);
  };
  const { number, balance_due } = invoice;
  const paid = +balance_due <= 0.0 ? "paid" : "unpaid";
  return (
    <PerViewContainer>
      <div className="header">
        <h2>
          {number ? number : Invoice} -{" "}
          {<span className={paid}>{paid.toUpperCase()}</span>}
        </h2>
      </div>
      <div className="invoice-btn-menu">
        <Button label="Download PDF" onClick={generatePDF} />
      </div>
      <LineItemsCard invoice={invoice} />
      <CommentsCard
        comments={filteredComments}
        ticket_id={ticket?.id}
        refetch={fetchTicketData}
      />
    </PerViewContainer>
  );
};

export default Invoice;
