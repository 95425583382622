// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  width: 100%;
  position: fixed;
  text-align: center;
  bottom: 0px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.322);
  z-index: 1002;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer .terms-conditions {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0px 2%;
}

.credit {
  text-align: left;
  padding: 15px 5px 0px 2%;
}

.contact-button {
  padding: 10px 5px 15px 2%;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,uBAAA;EACA,0CAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,kCAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,yBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,wBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".footer-container {\n  width: 100%;\n  position: fixed;\n  text-align: center;\n  bottom: 0px;\n  background-color: white;\n  border-top: 1px solid rgba(0, 0, 0, 0.322);\n  z-index: 1002;\n}\n\n.footer {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n\n  .terms-conditions {\n    display: flex;\n    justify-content: flex-end;\n    padding: 15px 15px 0px 2%;\n  }\n}\n\n.credit {\n  text-align: left;\n  padding: 15px 5px 0px 2%;\n}\n\n.contact-button {\n  padding: 10px 5px 15px 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
