// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-form {
  padding: 0 20px 20px 20px;
}
.account-form .btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/routes/account/account.styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ","sourcesContent":[".account-form {\n  padding: 0 20px 20px 20px;\n\n  .btn-container {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
