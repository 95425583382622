import "./footer.styles.scss";

import ContactUsButton from "../../components/contact-us-button/contact-us-button.component";
import Pdf from "../../assets/tcs-terms-and-conditions.pdf";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="credit">© 2023 - Techcycle Solutions</div>
        <div className="contact-button">
          <ContactUsButton />
        </div>
        <div className="terms-conditions">
          <a href={Pdf} target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
