import { createContext, useContext, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_SHIPPING_LABELS_QUERY,
  GET_SHIPPING_LABEL_QUERY,
} from "../api/queries/shipping-label.queries";
import { CREATE_SHIPPING_LABEL_MUTATION } from "../api/mutations/shipping-label.mutations";
import { MessagesContext } from "./messages.context";
import { UserContext } from "./user.context";

export const ShippingLabelsContext = createContext({
  shippingLabels: [],
  setShippingLabels: () => null,
  createShippingLabel: () => null,
  fetchShippingLabels: () => null,
});

export const ShippingLabelsProvider = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  const { addMessage } = useContext(MessagesContext);
  const [shippingLabels, setShippingLabels] = useState([]);
  const [createShippingLabelMutation] = useMutation(
    CREATE_SHIPPING_LABEL_MUTATION
  );
  const [getShippingLabel] = useLazyQuery(GET_SHIPPING_LABEL_QUERY);
  const [getShippingLabels, { refetch: refetchShippingLabels }] = useLazyQuery(
    GET_SHIPPING_LABELS_QUERY
  );

  const fetchShippingLabels = async (sort, direction, filter, refetch) => {
    try {
      if (refetch) {
        const response = await refetchShippingLabels({
          variables: {
            customer_id: currentUser.customer_id,
            contact_id: currentUser.role === "admin" ? null : currentUser.id,
            sort: sort ? `${direction === "asc" ? "" : "-"}${sort}` : null,
          },
        });
        if (!response || response.error)
          throw new Error("Error fetching shipping labels");
        return response.data.shippingLabels;
      }

      const response = await getShippingLabels(
        {
          variables: {
            customer_id: currentUser.customer_id,
            contact_id: currentUser.role === "admin" ? null : currentUser.id,
            sort: sort ? `${direction === "asc" ? "" : "-"}${sort}` : null,
          },
        },
        {
          networkPolicy: "network-only",
        }
      );
      if (!response || response.error)
        throw new Error("Error fetching shipping labels");
      return response.data.shippingLabels;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const createShippingLabel = async () => {
    try {
      addMessage("Creating shipping label...", "success");
      const response = await createShippingLabelMutation();
      if (!response?.data || response?.errors)
        throw new Error("Error creating shipping label");
      setShippingLabels([...shippingLabels, response.data.createShippingLabel]);
      addMessage("Shipping label created", "success");
      return response.data.createShippingLabel;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const fetchShippingLabel = async (id) => {
    try {
      const response = await getShippingLabel(
        {
          variables: {
            _id: id,
          },
        },
        {
          networkPolicy: "network-only",
        }
      );
      if (!response || response.error)
        throw new Error("Error fetching shipping label");
      return response.data.shippingLabel;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    shippingLabels,
    setShippingLabels,
    createShippingLabel,
    fetchShippingLabels,
    fetchShippingLabel,
  };
  return (
    <ShippingLabelsContext.Provider value={value}>
      {children}
    </ShippingLabelsContext.Provider>
  );
};
