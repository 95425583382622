import "./default.styles.scss";

const DefaultButton = ({ label, variant, ...otherProps }) => {
  return (
    <button
      {...otherProps}
      className={`default-button ${variant ? variant : ""}`}
    >
      {label}
    </button>
  );
};

export default DefaultButton;
