const { searchDefault } = require("./default.search");
const { searchTickets } = require("./ticket.search");

const searchAll = async (timedSearch, data, results, type) => {
  switch (type) {
    case "asset":
      return await searchDefault(timedSearch, data, results);
    case "ticket":
      return await searchTickets(timedSearch, data, results);
    case "user":
      return await searchDefault(timedSearch, data, results);
    case "invoices":
      return await searchDefault(timedSearch, data, results);
    default:
      return await searchDefault(timedSearch, data, results);
  }
};

module.exports = { searchAll };
