import "./large-table.styles.scss";
import LargeTableRow from "../large-table-row/large-table-row.component";

const getArrayType = (array, tableName) => {
  switch (tableName) {
    case "Tickets":
      return array.map((ticket) => {
        if (!ticket) return null;
        return {
          asset_serial: ticket?.assets[0]?.asset_serial,
          contact_name: ticket?.contact?.name,
          ...ticket,
        };
      });
    default:
      return array;
  }
};

const LargeTable = ({
  array = [],
  tableName,
  keyValue,
  label,
  fields,
  allowNavigate,
}) => {
  const tableArray = getArrayType(array, tableName);
  return (
    <div className="large-table tcs-container">
      <div className="table-header">
        <h2>{tableName}</h2>
      </div>
      <div className="table-body scrollbar">
        {tableArray?.length > 0 &&
          tableArray?.map((item, index) => (
            <LargeTableRow
              key={item[keyValue] || index}
              item={item}
              label={label}
              fields={fields}
              variant={index % 2 === 0 ? "primary" : "secondary"}
              allowNavigate={allowNavigate}
            />
          ))}
      </div>
    </div>
  );
};

export default LargeTable;
