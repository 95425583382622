import "./account.styles.scss";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import UpdateUserForm from "../../components/forms/user/update/update.user.form.component";

const Account = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <div className="account index-container">
      <div className="account-form">
        <UpdateUserForm user={currentUser} />
      </div>
    </div>
  );
};

export default Account;
