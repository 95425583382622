// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-img {
  padding: 4px;
  max-height: 60px;
  max-width: 60px;
}

.nav-img:hover {
  cursor: pointer;
}

.nav-img-container:hover {
  cursor: pointer;
}

.nav-img-container:hover {
  text-decoration: none;
  color: black;
}

.logo-container {
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links-container {
  margin-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.select {
  background-color: rgb(238, 238, 238);
}

.settings-dropdown {
  position: absolute;
  background-color: rgb(238, 238, 238);
  padding: 15px;
  border-radius: 10px;
  top: 100px;
  right: 105px;
}

@media screen and (max-width: 1200px) {
  .links-container {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/links-container/links-container.styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,kBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".nav-img {\n  padding: 4px;\n  max-height: 60px;\n  max-width: 60px;\n}\n\n.nav-img:hover {\n  cursor: pointer;\n}\n\n.nav-img-container:hover {\n  cursor: pointer;\n}\n\n.nav-img-container:hover {\n  text-decoration: none;\n  color: black;\n}\n\n.logo-container {\n  grid-column: 2 / 3;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.links-container {\n  margin-right: 30px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 20px;\n}\n\n.select {\n  background-color: rgb(238, 238, 238);\n}\n\n.settings-dropdown {\n  position: absolute;\n  background-color: rgb(238, 238, 238);\n  padding: 15px;\n  border-radius: 10px;\n  top: 100px;\n  right: 105px;\n}\n\n@media screen and (max-width: 1200px) {\n  .links-container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
