import DefaultButton from "../../default/default.button.component";
import { useNavigate } from "react-router-dom";

const AddUserNavigateButton = () => {
  const navigate = useNavigate();
  const goToCreateUser = () => {
    navigate("/users/create");
  };
  return <DefaultButton label="Add User" onClick={goToCreateUser} />;
};

export default AddUserNavigateButton;
