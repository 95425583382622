import { useContext } from "react";
import { Outlet, Link } from "react-router-dom";

import { UserContext } from "../../contexts/user.context";

import "./navbar.styles.scss";

import Footer from "../../components/footer/footer.component";
import Messages from "../../components/messages/messages.component";
import LinksContainer from "../../components/links-container/links-container.component";
import ChangePasswordModal from "../../components/change-password-modal/change-password-modal.component";
const Navbar = () => {
  const { currentUser } = useContext(UserContext);
  return (
    <>
      <div className="navbar">
        <Link
          className="logo-container"
          to={`${currentUser ? "/tickets" : "/login"}`}
        >
          <div className="brand">
            <span className="brand-name1">Techcycle</span>
            <span className="brand-name2"> Solutions</span>
          </div>
        </Link>
        {currentUser && <LinksContainer />}
      </div>
      {currentUser?.require_password_change && (
        <ChangePasswordModal show={true} user={currentUser._id} />
      )}
      <Messages />
      <Outlet />
      <Footer />
    </>
  );
};

export default Navbar;
