// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.large-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  height: calc(100vh - 370px);
  gap: 5px;
}
.large-table .table-header {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.large-table .table-body {
  margin: auto auto 10px auto;
  width: 100%;
  height: 100%;
  align-items: start;
  justify-content: center;
  padding: 10px 5px 10px 5px;
  gap: 10px;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/components/large-table/large-table.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,2BAAA;EACA,QAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,4BAAA;EACA,6BAAA;AACJ;AAEE;EACE,2BAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,0BAAA;EACA,SAAA;EACA,kBAAA;AAAJ","sourcesContent":[".large-table {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  min-height: 300px;\n  height: calc(100vh - 370px);\n  gap: 5px;\n\n  .table-header {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background-color: white;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n  }\n\n  .table-body {\n    margin: auto auto 10px auto;\n    width: 100%;\n    height: 100%;\n    align-items: start;\n    justify-content: center;\n    padding: 10px 5px 10px 5px;\n    gap: 10px;\n    overflow-y: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
