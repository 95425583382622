import "./link-asset-csv.styles.scss";
import Button from "../button/button.component";

const LinkAssetCsv = () => {
  const openSpreadsheet = () => {
    // Replace this URL with the URL of your Google Spreadsheet
    const googleSpreadsheetUrl =
      "https://docs.google.com/spreadsheets/d/14s8ywItiG3NtzDU0qJ9Ubx9f-Rd3Jv-F6ThxT7xfiMA/edit?usp=sharing";

    // Open the Google Spreadsheet in a new tab
    window.open(googleSpreadsheetUrl, "_blank");
  };
  return (
    <div className="link-asset-csv">
      <Button onClick={openSpreadsheet} label="Asset Template" />
    </div>
  );
};

export default LinkAssetCsv;
