import "./assets.styles.scss";
import LargeTable from "../../components/large-table/large-table.component";
import { useContext, useState, useEffect } from "react";
import { AssetsContext } from "../../contexts/assets.context";
import { UserContext } from "../../contexts/user.context";
import AssetSearch from "../../components/asset-search/asset-search.component";
import FormSelect from "../../components/form-select/form-select.component";
import CSVUploader from "../../components/csv-uploader/csv-uploader.component";
import LinkAssetCsv from "../../components/link-asset-csv/link-asset-csv.component";

const assetFields = [
  { label: "Make", value: "make" },
  { label: "Model", value: "model" },
];

const sortOptions = [
  {
    label: "Asset Serial",
    value: "asset_serial",
  },
  {
    label: "Make",
    value: "make",
  },
  {
    label: "Model",
    value: "model",
  },
];

const directionOptions = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
];

const defaultFormFields = {
  sort: "asset_serial",
  direction: "desc",
};

const Assets = () => {
  const [assets, setAssets] = useState([]);
  const { currentUser } = useContext(UserContext);
  const { fetchAssets } = useContext(AssetsContext);
  const [results, setResults] = useState("");
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { sort, direction } = formFields;

  useEffect(() => {
    fetchAssets(sort, direction).then((res) => setAssets(res));
  }, [sort, direction, currentUser]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="assets index-container">
      <div className="assets-btn-menu">
        <CSVUploader />
        <LinkAssetCsv />
      </div>
      <div className="filter tcs-container">
        <div className="filter-item">
          <AssetSearch assets={assets} results={setResults} />
        </div>
        <div className="filter-item">
          <FormSelect
            label="Sort"
            options={sortOptions}
            onChange={handleChange}
            name="sort"
            value={sort}
          />
          <FormSelect
            label="Direction"
            options={directionOptions}
            onChange={handleChange}
            name="direction"
            value={direction}
          />
        </div>
      </div>
      <LargeTable
        array={results ? results : assets}
        tableName="Assets"
        keyValue="_id"
        label="asset_serial"
        fields={assetFields}
      />
    </div>
  );
};

export default Assets;
