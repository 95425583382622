import "./estimate-status.styles.scss";

const estimateStatusOptions = [
  { value: "Approved", label: "Approved" },
  { value: "Declined", label: "Declined" },
  { value: "Fresh", label: "New" },
];

const EstimateStatusSelectFormField = ({
  status,
  handleChange,
  otherProps,
}) => {
  return (
    <>
      <div className="estimate-status-select-form-field">
        <select
          className={`${status}`}
          onChange={handleChange}
          value={status}
          name="status"
          {...otherProps}
        >
          <option disabled value=""></option>
          {estimateStatusOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
              className={`${option.value}`}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default EstimateStatusSelectFormField;
