import "./link-ticket-csv.styles.scss";
import Button from "../button/button.component";

const LinkTicketCsv = () => {
  const openSpreadsheet = () => {
    // Replace this URL with the URL of your Google Spreadsheet
    const googleSpreadsheetUrl =
      "https://docs.google.com/spreadsheets/d/1YKikeqO_MU8f7yyfZ2CV3jPYrLVJJpgk85b16oeuovo/edit?usp=sharing";

    // Open the Google Spreadsheet in a new tab
    window.open(googleSpreadsheetUrl, "_blank");
  };
  return (
    <div className="link-ticket-csv">
      <Button onClick={openSpreadsheet} label="Ticket Template" />
    </div>
  );
};

export default LinkTicketCsv;
