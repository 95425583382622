import "./user.styles.scss";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";

import UpdateUserForm from "../../components/forms/user/update/update.user.form.component";

const User = () => {
  const { id } = useParams();
  const { getUser } = useContext(UserContext);
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser(id).then((res) => setUser(res));
  }, [id]);

  if (!user) return null;
  return (
    <div className="user-container index-container">
      <UpdateUserForm user={user} />
    </div>
  );
};

export default User;
