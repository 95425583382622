import "./login.styles.scss";

import LoginForm from "../../components/login-form/login-form.component";

const Login = () => {
  return (
    <>
      <div className="login index-container">
        <LoginForm />
      </div>
    </>
  );
};

export default Login;
