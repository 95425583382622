import "./hamburger-menu.styles.scss";
import { ReactComponent as HamburgerMenuIcon } from "../../assets/icons/hamburger-menu.svg";
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";

const HamburgerMenu = () => {
  const location = useLocation();
  const { logout, currentUser } = useContext(UserContext);
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownContainer = useRef();

  // Close dropdowns when location changes
  useEffect(() => {
    if (dropdown) setDropdown(null);
  }, [location]);

  useEffect(() => {
    const handleClick = (e) => {
      if (!dropdownContainer?.current?.contains(e.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  const goToAssets = () => navigate("/assets");
  const goToTickets = () => navigate("/tickets");
  const goToUsers = () => navigate("/users");
  const goToShippingLabels = () => navigate("/shipping-labels");
  const goToInvoices = () => navigate("/invoices");
  const goToPOs = () => navigate("/pos");
  const goToAccount = () => navigate("/account");
  const goToEstimates = () => navigate("/estimates");
  const handleLogout = () => {
    logout();
  };
  return (
    <div className="hamburger-menu" ref={dropdownContainer}>
      <HamburgerMenuIcon
        className="hamburger-menu-icon"
        onClick={toggleDropdown}
      />
      <ul className={`dropdown ${dropdown ? "" : "hidden"}`}>
        <li className="dropdown-item" onClick={goToAssets}>
          Assets
        </li>
        <li className="dropdown-item" onClick={goToEstimates}>
          Estimates
        </li>
        {currentUser?.role === "admin" && (
          <>
            <li className="dropdown-item" onClick={goToInvoices}>
              Invoices
            </li>
            <li className="dropdown-item" onClick={goToPOs}>
              PO's
            </li>
          </>
        )}
        <li className="dropdown-item" onClick={goToShippingLabels}>
          Labels
        </li>
        <li className="dropdown-item" onClick={goToTickets}>
          Tickets
        </li>
        {currentUser?.role === "admin" && (
          <li className="dropdown-item" onClick={goToUsers}>
            Users
          </li>
        )}
        <li className="dropdown-item" onClick={goToAccount}>
          Account
        </li>
        <li className="dropdown-item" onClick={handleLogout}>
          Logout
        </li>
      </ul>
    </div>
  );
};

export default HamburgerMenu;
