import { useEffect, useContext, useState, createContext } from "react";
import { CREATE_TICKET_MUTATION } from "../api/mutations/ticket.mutations";
import { useMutation } from "@apollo/client";
import { MessagesContext } from "./messages.context";

const defaultTicketForm = {
  make: "",
  model: "",
  asset_serial: "",
  comment: "",
  problem_type: "Other",
  tracking_number: "",
  asset_type: "",
  school: "",
  user_type: "",
};

export const TicketFormContext = createContext({
  ticketForm: {},
  setTicketForm: () => null,
  selectedAsset: "",
  setSelectedAsset: () => null,
  selectedShippingLabel: {},
  setSelectedShippingLabel: () => null,
  createTicket: () => null,
});

export const TicketFormProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const [ticketForm, setTicketForm] = useState(defaultTicketForm);
  const { make, model, asset_serial, problem_type } = ticketForm;
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedShippingLabel, setSelectedShippingLabel] = useState({});
  const [createTicketMutation] = useMutation(CREATE_TICKET_MUTATION);

  useEffect(() => {
    setTicketForm({
      ...ticketForm,
      asset_serial: selectedAsset?.asset_serial || "",
      make: selectedAsset?.make || "",
      model: selectedAsset?.model || "",
      tracking_number: selectedShippingLabel?.tracking_number || "",
      asset_type: selectedAsset?.asset_type || "",
    });
  }, [selectedAsset, selectedShippingLabel]);
  const createTicket = async () => {
    try {
      if (selectedAsset && selectedAsset?.warranty) {
        if (new Date(selectedAsset?.warranty_end_date) < new Date()) {
          if (!window.confirm("Asset is out of warranty. Continue?")) return;
        }
      }
      const { data } = await createTicketMutation({
        variables: {
          ...ticketForm,
          subject: `${make} ${model} - ${asset_serial}: ${problem_type}`,
        },
      });
      if (!data || data.error) throw new Error("Error creating ticket");
      addMessage(`Ticket ${data.createTicket.number} created!`, "success");
      setTicketForm(defaultTicketForm);
      setSelectedAsset("");
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    ticketForm,
    setTicketForm,
    selectedAsset,
    setSelectedAsset,
    selectedShippingLabel,
    setSelectedShippingLabel,
    createTicket,
  };
  return (
    <TicketFormContext.Provider value={value}>
      {children}
    </TicketFormContext.Provider>
  );
};
