import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./user.context";
import { MessagesContext } from "./messages.context";
import { useLazyQuery, useMutation } from "@apollo/client";
import { downloadPDF } from "../utils/pdf/generate-pdf.js";
import {
  GET_INVOICES_QUERY,
  GET_INVOICE_QUERY,
} from "../api/queries/invoice.queries";
import { GET_INVOICE_PDF } from "../api/mutations/invoice.mutations";

export const InvoicesContext = createContext({
  invoices: [],
  setInvoices: () => null,
  fetchInvoices: () => null,
  fetchInvoice: () => null,
  downloadInvoicePDF: () => null,
});

export const InvoicesProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(UserContext);
  const [invoices, setInvoices] = useState([]);
  const [getInvoicePDF] = useMutation(GET_INVOICE_PDF);
  const [getInvoice] = useLazyQuery(GET_INVOICE_QUERY);
  const [getInvoices, { data: getInvoicesData }] =
    useLazyQuery(GET_INVOICES_QUERY);

  useEffect(() => {
    if (getInvoicesData?.invoices.length > 0) {
      setInvoices(getInvoicesData.invoices);
    }
  }, [getInvoicesData]);

  const fetchInvoices = async (sort, direction) => {
    try {
      if (!currentUser) return;
      const response = await getInvoices({
        variables: {
          customer_id: currentUser.customer_id,
          sort: `${direction === "asc" ? "" : "-"}${sort}`,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      return response.data.invoices.map((invoice) => {
        return { ...invoice, ticket_number: invoice?.ticket?.number };
      });
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const fetchInvoice = async (_id) => {
    try {
      if (!currentUser) return;
      const response = await getInvoice({
        variables: {
          _id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      return response?.data?.invoice;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const downloadInvoicePDF = async (_id, number) => {
    try {
      if (!currentUser || !_id) throw new Error("No user or invoice id");
      const response = await getInvoicePDF({
        variables: {
          _id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      downloadPDF(response?.data?.invoicePDF, `TCS Invoice - ${number}`);
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    invoices,
    setInvoices,
    fetchInvoices,
    fetchInvoice,
    downloadInvoicePDF,
  };
  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  );
};
