import "./new-ticket.styles.scss";
import { useState } from "react";
import NewTicketSidebar from "../../components/new-ticket-sidebar/new-ticket-sidebar.component";
import CreateTicketForm from "../../components/forms/ticket/create/create.ticket.form.component";

const NewTicket = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="new-ticket-container">
      <NewTicketSidebar setSidebar={setSidebarOpen} />
      <div className={`new-ticket-body ${sidebarOpen ? "add-margin" : ""}`}>
        <div className="new-ticket-form tcs-container">
          <CreateTicketForm />
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
