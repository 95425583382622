import "./create-user.styles.scss";
import CreateUserForm from "../../components/forms/user/create/create.user.form.component";

const CreateUser = () => {

  return (
    <div className="create-user index-container">
      <CreateUserForm />
    </div>
  );
};

export default CreateUser;
