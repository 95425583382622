import { createContext, useEffect, useState, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { UserContext } from "./user.context";
import { GET_CUSTOMER_QUERY } from "../api/queries/customer.queries";
import { MessagesContext } from "./messages.context";

export const CustomerContext = createContext({
  customer: null,
  setCustomer: () => null,
});

export const CustomerProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(UserContext);
  const [getCustomer] = useLazyQuery(GET_CUSTOMER_QUERY);
  const [customer, setCustomer] = useState({});

  const fetchCustomer = async (id) => {
    try {
      if (!id || !currentUser) return;
      const response = await getCustomer({
        variables: {
          id: id,
        },
      });
      if (response?.error || !response?.data?.customer)
        throw new Error(response.error);
      return response?.data?.customer;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  useEffect(() => {
    if (currentUser?.customer_id && !customer.length) {
      (async () => setCustomer(await fetchCustomer(currentUser.customer_id)))();
    }
  }, [currentUser]);

  const value = {
    customer,
    setCustomer,
  };

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};
