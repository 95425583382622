import { createContext, useContext, useState } from "react";
import { UserContext } from "./user.context";
import { MessagesContext } from "./messages.context";
import { GET_ASSETS, GET_ASSET } from "../api/queries/asset.queries";
import { useLazyQuery } from "@apollo/client";
import { issueTypesDefault } from "../assets/ticket-options";

export const AssetsContext = createContext({
  assets: null,
  setAssets: () => null,
  deviceModels: null,
  setDevModels: () => null,
  deviceMakes: null,
  setDeviceMakes: () => null,
  deviceTypes: null,
  setDeviceTypes: () => null,
  issueTypes: null,
  setIssueTypes: () => null,
  fetchAssets: () => null,
  fetchAsset: () => null,
});

export const AssetsProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(UserContext);
  const [getAssets] = useLazyQuery(GET_ASSETS);
  const [getAsset] = useLazyQuery(GET_ASSET);
  const [assets, setAssets] = useState([]);
  const [issueTypes, setIssueTypes] = useState(issueTypesDefault);
  const fetchAssets = async (sort, direction) => {
    try {
      if (!currentUser.id) return;
      const response = await getAssets({
        variables: {
          customer_id: currentUser.customer_id,
          sort: `${direction === "asc" ? "" : "-"}${sort}`,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      setAssets(response.data.getAssets);
      return response.data.getAssets;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const fetchAsset = async (asset_id) => {
    try {
      const response = await getAsset({
        variables: { _id: asset_id },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      return response.data.asset;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    assets,
    setAssets,
    issueTypes,
    fetchAssets,
    fetchAsset,
  };

  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
};
