// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments {
  padding: 10px 20px 20px 20px;
  min-width: 50%;
}
.comments .header {
  border-bottom: 1px solid #8dc63f;
}
.comments .body .comment {
  margin-top: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.comments .body .comment .subject {
  font-weight: bold;
}
.comments .body .comment .comment-row {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
}
.comments .body .comment .comment-row .date-time {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #8dc63f;
  padding-right: 10px;
  width: 20%;
  min-width: 80px;
}
.comments .body .comment .comment-row .comment-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/comments-card/comments-card.styles.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,cAAA;AACF;AACE;EACE,gCAAA;AACJ;AAGI;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,iDAAA;AADN;AAGM;EACE,iBAAA;AADR;AAGM;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AADR;AAGQ;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,+BAAA;EACA,mBAAA;EACA,UAAA;EACA,eAAA;AADV;AAIQ;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAFV","sourcesContent":[".comments {\n  padding: 10px 20px 20px 20px;\n  min-width: 50%;\n\n  .header {\n    border-bottom: 1px solid #8dc63f;\n  }\n\n  .body {\n    .comment {\n      margin-top: 10px;\n      padding: 8px;\n      display: flex;\n      flex-direction: column;\n      border: none;\n      border-radius: 5px;\n      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n\n      .subject {\n        font-weight: bold;\n      }\n      .comment-row {\n        display: flex;\n        flex-direction: row;\n        column-gap: 10px;\n        width: 100%;\n\n        .date-time {\n          display: flex;\n          justify-content: center;\n          align-items: center;\n          border-right: 1px solid #8dc63f;\n          padding-right: 10px;\n          width: 20%;\n          min-width: 80px;\n        }\n\n        .comment-body {\n          display: flex;\n          justify-content: flex-start;\n          align-items: center;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
