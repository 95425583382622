import { useState, useEffect } from "react";
import "./progress-bar.styles.scss";
import BlurredText from "../blurred-text/blurred-text.component";

const ProgressBar = ({ progress, children }) => {
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    setCurrentProgress(progress);
  }, [progress]);

  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{
          width: `${currentProgress}%`,
          background: `rgba(142, 198, 63, ${currentProgress / 100})`,
        }}
      ></div>
      <div className="progress-bar-children">
        {children &&
          children.map((child, index) => (
            <BlurredText
              key={index}
              text={child.text}
              tooltip={child.tooltip}
            />
          ))}
      </div>
    </div>
  );
};

export default ProgressBar;
