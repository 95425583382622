import "./blurred-text.styles.scss";
import Tooltip from "../tooltip/tooltip.component";

const BlurredText = ({ text, tooltip }) => {
  return (
    <div className="blurred-text parent-hover">
      {text}
      {tooltip && <Tooltip content={tooltip} />}
    </div>
  );
};

export default BlurredText;
