const statusOptions = [
  "Additional Time Needed",
  "BER Approved",
  "BER Recommended",
  "Buyout Requested",
  "Contact Customer",
  "Customer Reply",
  "Delayed - Pricing Requested",
  "Delayed - Repair Approved",
  "Delayed - Repair Request",
  "Denied - Return to Customer",
  "In Progress",
  "IW - Waiting on Part",
  "New",
  "OW - Waiting on Part",
  "Part(s) Arrived",
  "Parts on Backorder",
  "Pending Courier Pick-up/Delivery",
  "Pending QA",
  "Pending QA - No Repair Needed",
  "Pending Reassembly",
  "QA Review",
  "Ready for Invoicing",
  "Ready for Repair",
  "Ready for shipment",
  "Received",
  "Repair Complete",
  "Resolved",
  "Resolved - BER",
  "Resolved - Buyout",
  "Resolved - Canceled",
  "Resolved - Denied",
  "Resolved- Shipped",
  "Resolved - Unable To Repair",
  "Scheduled",
  "Shipping Label Sent",
  "Solder Queue",
  "Uploaded to Shipstore",
  "Waiting for Parts",
  "Waiting on BER Credit",
  "Waiting on Customer",
];

const issueTypesDefault = [
  { value: "Broken Glass", label: "Broken Glass" },
  { value: "Broken LCD", label: "Broken LCD" },
  { value: "Button", label: "Button" },
  { value: "Camera", label: "Camera" },
  { value: "Charging", label: "Charging" },
  { value: "Cosmetic", label: "Cosmetic" },
  { value: "Diagnostics", label: "Diagnostics" },
  { value: "Frame/Assembly/Case Damage", label: "Frame/Assembly/Case Damage" },
  { value: "Headphone Jack", label: "Headphone Jack" },
  { value: "Keyboard", label: "Keyboard" },
  { value: "Key Replacement/Repair", label: "Key Replacement/Repair" },
  { value: "Microphone", label: "Microphone" },
  { value: "No Power", label: "No Power" },
  { value: "Other", label: "Other" },
  { value: "Software", label: "Software" },
  { value: "Speaker", label: "Speaker" },
  { value: "Touch", label: "Touch" },
  { value: "Touchpad", label: "Touchpad" },
];

const MAKE_OPTIONS = [
  { value: "Acer", label: "Acer" },
  { value: "Apple", label: "Apple" },
  { value: "Asus", label: "Asus" },
  { value: "Dell", label: "Dell" },
  { value: "HP", label: "HP" },
  { value: "Lenovo", label: "Lenovo" },
  { value: "Microsoft", label: "Microsoft" },
  { value: "Other", label: "Other" },
  { value: "Samsung", label: "Samsung" },
  { value: "Sony", label: "Sony" },
  { value: "Toshiba", label: "Toshiba" },
];

module.exports = { statusOptions, issueTypesDefault, MAKE_OPTIONS };
