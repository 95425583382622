import "./asset-sidebar-table.styles.scss";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import AssetSearch from "../asset-search/asset-search.component";
import { AssetsContext } from "../../contexts/assets.context";
import AssetSmallTable from "../asset-small-table/asset-small-table";

const AssetSidebarTable = () => {
  const [assets, setAssets] = useState([]);
  const { fetchAssets } = useContext(AssetsContext);
  const { currentUser } = useContext(UserContext);
  const [results, setResults] = useState("");

  useEffect(() => {
    fetchAssets().then((res) => setAssets(res));
  }, [currentUser]);
  return (
    <div className="asset-sidebar-table tcs-container">
      <div className="table">
        <div className="table-header">
          <label className="table-label">Assets</label>
          <AssetSearch assets={assets} results={setResults} />
        </div>
        <AssetSmallTable
          array={results ? results : assets}
          key={"id"}
          label={"asset_serial"}
        />
      </div>
    </div>
  );
};

export default AssetSidebarTable;
