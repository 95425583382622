// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  visibility: hidden;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 3px;
  margin-left: -30px;
  opacity: 0;
  transition: opacity 0.3s;
  width: 100px;
  background: rgba(142, 198, 63, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* This class should be applied to the parent element of the tooltip */
.parent-hover:hover .tooltip {
  visibility: visible;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/tooltip.styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;EACA,UAAA;EACA,wBAAA;EACA,YAAA;EAEA,oCAAA;EACA,gDAAA;EACA,0BAAA;EACA,kCAAA;EACA,mBAAA;EACA,2CAAA;AAAF;;AAGA,sEAAA;AACA;EACE,mBAAA;EACA,UAAA;AAAF","sourcesContent":[".tooltip {\n  visibility: hidden;\n  font-weight: bold;\n  text-align: center;\n  padding: 5px;\n  position: absolute;\n  z-index: 1;\n  top: 100%;\n  left: 3px;\n  margin-left: -30px;\n  opacity: 0;\n  transition: opacity 0.3s;\n  width: 100px;\n\n  background: rgba(142, 198, 63, 0.25);\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n  backdrop-filter: blur(4px);\n  -webkit-backdrop-filter: blur(4px);\n  border-radius: 10px;\n  border: 1px solid rgba(255, 255, 255, 0.18);\n}\n\n/* This class should be applied to the parent element of the tooltip */\n.parent-hover:hover .tooltip {\n  visibility: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
