import "./create-comment.styles.scss";
import { TicketsContext } from "../../contexts/tickets.context";
import { UserContext } from "../../contexts/user.context";
import { MessagesContext } from "../../contexts/messages.context";
import FormTextArea from "../form-text-area/form-text-area.component";
import Button from "../button/button.component";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { useContext, useState } from "react";

const CreateComment = ({ ticket_id, refetch, ...rest }) => {
  const [comment, setComment] = useState("");
  const { currentUser } = useContext(UserContext);
  const { addComment } = useContext(TicketsContext);
  const { addMessage } = useContext(MessagesContext);

  const changeHandler = ({ target }) => {
    setComment(target.value);
  };
  const onClose = (e) => {
    e.preventDefault();
    setComment("");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return addMessage("Comment cannot be empty", "error");
    const response = await addComment(
      ticket_id,
      "Customer Reply",
      comment,
      `${currentUser?.name || "Customer Comment"}`
    );
    if (!response) return;
    setComment("");
    refetch();
  };
  return (
    <div className="create-comment">
      <FormTextArea
        label="Add Comment"
        value={comment}
        onChange={changeHandler}
        rows="4"
      />
      <CloseIcon className="close-btn" onClick={onClose} />
      <Button label="Submit" onClick={onSubmit} />
    </div>
  );
};

export default CreateComment;
