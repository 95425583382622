// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-modal-body {
  border-radius: 10px;
  background-color: whitesmoke;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}
.contact-modal-body .contact-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 290px;
  padding: 8px;
}
.contact-modal-body .submit-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.contact-textarea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  padding: 8px;
}
.contact-textarea label {
  display: flex;
  justify-content: right;
  width: 30%;
  margin-right: 10px;
}
.contact-textarea textarea {
  width: 60%;
  border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/contact-us-button/contact-us-button.styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;AACJ;AAEE;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;AADJ;AAIE;EACE,UAAA;EACA,kBAAA;AAFJ","sourcesContent":[".contact-modal-body {\n  border-radius: 10px;\n  background-color: whitesmoke;\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding: 20px;\n\n  .contact-input-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 290px;\n    padding: 8px;\n  }\n\n  .submit-btn {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n\n.contact-textarea {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 50%;\n  padding: 8px;\n\n  label {\n    display: flex;\n    justify-content: right;\n    width: 30%;\n    margin-right: 10px;\n  }\n\n  textarea {\n    width: 60%;\n    border-radius: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
