import "./po-cell.styles.scss";

const POCell = ({ label, children }) => {
  return (
    <div className="po-cell">
      <label className="cell-label">{label}</label>
      <div className="cell-value">{children}</div>
    </div>
  );
};

export default POCell;
