// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-dropdown {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.account-dropdown .image-container {
  text-decoration: none;
  border-radius: 3px;
  color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 70px;
}
.account-dropdown .image-container:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
}
.account-dropdown .dropdown {
  position: absolute;
  background-color: rgba(142, 198, 63, 0.952);
  background: rgba(142, 198, 63, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 15px;
  border-radius: 10px;
  top: 95px;
}
.account-dropdown .dropdown .dropdown-item {
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  cursor: pointer;
}
.account-dropdown .dropdown .dropdown-item:hover {
  background-color: #8dc63f;
}`, "",{"version":3,"sources":["webpack://./src/components/account-dropdown/account-dropdown.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,YAAA;AACF;AACE;EACE,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;AACJ;AAGI;EACE,eAAA;EACA,uBAAA;EACA,kBAAA;AADN;AAKE;EACE,kBAAA;EACA,2CAAA;EACA,oCAAA;EACA,gDAAA;EACA,kCAAA;UAAA,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAHJ;AAKI;EACE,kBAAA;EACA,0BAAA;EACA,iBAAA;EAEA,eAAA;AAJN;AAMM;EACE,yBAAA;AAJR","sourcesContent":[".account-dropdown {\n  display: flex;\n  justify-content: flex-end;\n  height: 100%;\n\n  .image-container {\n    text-decoration: none;\n    border-radius: 3px;\n    color: black;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    max-width: 70px;\n  }\n\n  .image-container {\n    &:hover {\n      cursor: pointer;\n      background-color: white;\n      border-radius: 5px;\n    }\n  }\n\n  .dropdown {\n    position: absolute;\n    background-color: rgba(142, 198, 63, 0.952);\n    background: rgba(142, 198, 63, 0.25);\n    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n    backdrop-filter: blur(4px);\n    padding: 15px;\n    border-radius: 10px;\n    top: 95px;\n\n    .dropdown-item {\n      border-radius: 3px;\n      padding: 5px 10px 5px 10px;\n      font-weight: bold;\n\n      cursor: pointer;\n\n      &:hover {\n        background-color: #8dc63f;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
