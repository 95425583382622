import "./state.styles.scss";
import FormSelect from "../../../form-select/form-select.component";
import US_STATES from "../../../../constants/us-states.constant";

const StateSelectFormField = ({ state, handleChange }) => {
  return (
    <FormSelect
      label="State"
      options={US_STATES}
      name="state"
      value={state}
      onChange={handleChange}
    />
  );
};

export default StateSelectFormField;
