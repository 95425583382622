// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csv-uploader input[type=file] {
  display: none;
}
.csv-uploader label {
  display: inline-block;
  background: #8dc63f;
  color: #fff;
  text-align: center;
  padding: 6px 12px 6px 12px;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.csv-uploader label:hover {
  box-shadow: 0 0 6px 2px rgb(141, 198, 63);
}`, "",{"version":3,"sources":["webpack://./src/components/csv-uploader/csv-uploader.styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,qBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,0BAAA;EACA,yBAAA;UAAA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AADJ;AAGI;EACE,yCAAA;AADN","sourcesContent":[".csv-uploader {\n  input[type=\"file\"] {\n    display: none;\n  }\n\n  label {\n    display: inline-block;\n    background: #8dc63f;\n    color: #fff;\n    text-align: center;\n    padding: 6px 12px 6px 12px;\n    user-select: none;\n    border-radius: 5px;\n    border: none;\n    cursor: pointer;\n\n    &:hover {\n      box-shadow: 0 0 6px 2px rgb(141, 198, 63);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
