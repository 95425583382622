import "./tickets.styles.scss";
import { useNavigate } from "react-router-dom";
import Filter from "../../components/filter/filter.component";
import { useContext, useEffect, useState } from "react";
import { TicketsContext } from "../../contexts/tickets.context";
import Button from "../../components/button/button.component";
import { UserContext } from "../../contexts/user.context";
import LargeTable from "../../components/large-table/large-table.component";
import LinkTicketCsv from "../../components/link-ticket-csv/link-ticket-csv.component";
import TicketCSVUploader from "../../components/ticket-csv-uploader/ticket-csv-uploader.component";

const defaultFormFields = {
  sort: "number",
  direction: "desc",
};

const ticketFields = [
  { label: "Status", value: "status" },
  { label: "Created", value: "created_at" },
  { label: "Updated", value: "updated_at" },
  { label: "Resolved", value: "resolved_at" },
  { label: "Asset Serial", value: "asset_serial" },
  { label: "Created By", value: "contact_name" },
];

const Tickets = () => {
  const { currentUser } = useContext(UserContext);
  const [tickets, setTickets] = useState([]);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { sort, direction } = formFields;
  const [results, setResults] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const { fetchTickets } = useContext(TicketsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && statusFilter.length)
      fetchTickets(sort, direction, statusFilter).then((tickets) => {
        setTickets(tickets);
      });
  }, [sort, direction, statusFilter, currentUser]);

  const goToNewTicket = () => {
    navigate("/tickets/new");
  };
  const goToPendingTickets = () => {
    navigate("/tickets/pending");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="tickets index-container">
      <div className="tickets-btn-menu">
        <Button label="New Ticket" onClick={goToNewTicket} />
        <TicketCSVUploader />
        <LinkTicketCsv />
        <Button
          variant="blue"
          label="Pending Tickets"
          onClick={goToPendingTickets}
        />
      </div>
      <Filter
        handleChange={handleChange}
        formFields={formFields}
        searchData={tickets}
        searchType={"ticket"}
        results={setResults}
        statusFilter={setStatusFilter}
      />
      <LargeTable
        array={results ? results : tickets}
        tableName="Tickets"
        keyValue="_id"
        label="number"
        fields={ticketFields}
      />
      {/* <TicketTable options={{ sort, direction, statusFilter }} /> */}
    </div>
  );
};

export default Tickets;
