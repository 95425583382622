import "./select-row.styles.scss";

const VARIANT_TYPE_CLASSES = {
  primary: "primary",
  secondary: "secondary",
};

const SelectRow = ({ label, variant, selected, ...otherProps }) => {
  return (
    <>
      <div
        className={`${VARIANT_TYPE_CLASSES[variant]} ${
          selected ? "selected" : ""
        }`}
        {...otherProps}
      >
        <label>{label}</label>
      </div>
    </>
  );
};

export default SelectRow;
