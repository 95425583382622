// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-user-form .header {
  padding: 4px 12px 4px 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.update-user-form .header h5 {
  margin: 0;
}
.update-user-form .body {
  padding: 0 20px 20px 20px;
}
.update-user-form .body .btn-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/user/update/update.styles.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;EACA,4BAAA;EACA,6BAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACE,SAAA;AAAN;AAIE;EACE,yBAAA;AAFJ;AAII;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFN","sourcesContent":[".update-user-form {\n  .header {\n    padding: 4px 12px 4px 12px;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    background: white;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n\n    h5 {\n      margin: 0;\n    }\n  }\n\n  .body {\n    padding: 0 20px 20px 20px;\n\n    .btn-container {\n      margin-top: 20px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      column-gap: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
