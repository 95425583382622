// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.form-container .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 275px;
}

.login-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
}
.login-field input {
  width: 275px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.login-field-label {
  align-self: flex-start;
  padding: 5px;
  font-weight: bold;
}

.login-field-input {
  padding-right: 0;
  justify-content: end;
  border: none;
  border-radius: 5px;
}
.login-field-input:focus {
  outline: none;
}

.login-btn {
  margin: 15px auto 15px auto;
}

.login-message {
  margin: 10px;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgb(255, 223, 223);
}

.reset-btn {
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1877f2;
}
.reset-btn:hover {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/login-form/login-form.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;AAAF;AAEE;EACE,YAAA;EACA,iDAAA;AAAJ;;AAIA;EACE,sBAAA;EACA,YAAA;EACA,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;AADF;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,2BAAA;AAFF;;AAKA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,oCAAA;AAFF;;AAKA;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAFF;AAIE;EACE,eAAA;EACA,0BAAA;AAFJ","sourcesContent":[".form-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n\n  .login-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n.login-fields {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 275px;\n}\n\n.login-field {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 10px;\n  width: 100%;\n  justify-content: flex-end;\n\n  input {\n    width: 275px;\n    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n  }\n}\n\n.login-field-label {\n  align-self: flex-start;\n  padding: 5px;\n  font-weight: bold;\n}\n\n.login-field-input {\n  padding-right: 0;\n  justify-content: end;\n  border: none;\n  border-radius: 5px;\n\n  &:focus {\n    outline: none;\n  }\n}\n\n.login-btn {\n  margin: 15px auto 15px auto;\n}\n\n.login-message {\n  margin: 10px;\n  padding: 8px;\n  border-radius: 5px;\n  font-weight: bold;\n  background-color: rgb(255, 223, 223);\n}\n\n.reset-btn {\n  font-size: 0.6rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #1877f2;\n\n  &:hover {\n    cursor: pointer;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
