import "./pending-tickets.styles.scss";
import { GET_PENDING_TICKETS } from "../../api/queries/pending-tickets.queries";
import { useLazyQuery } from "@apollo/client";
import { UserContext } from "../../contexts/user.context";
import React, { useContext, useEffect, useState } from "react";
import LargeTable from "../../components/large-table/large-table.component";

const pendingTicketFields = [
  {
    label: "Created",
    value: "created_at",
    type: "date",
  },
];

const PendingTickets = () => {
  const { currentUser } = useContext(UserContext);
  const [pendingTickets, setPendingTickets] = useState([]);
  const [getPendingTickets] = useLazyQuery(GET_PENDING_TICKETS);
  const fetchPendingTickets = async () => {
    if (!currentUser) return;
    const response = await getPendingTickets({
      variables: { contact_id: currentUser._id },
      fetchPolicy: "network-only",
    });
    setPendingTickets(response.data.pendingTickets);
  };
  useEffect(() => {
    fetchPendingTickets();
  }, []);
  return (
    <div className="pending-tickets index-container">
      <LargeTable
        array={pendingTickets}
        tableName="Pending Tickets"
        keyValue="_id"
        label="asset_serial"
        fields={pendingTicketFields}
        allowNavigate={false}
      />
    </div>
  );
};

export default PendingTickets;
