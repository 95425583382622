import "./user.delete.button.styles.scss";
import DefaultButton from "../../default/default.button.component";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/user.context";

const UserDeleteButton = ({ user }) => {
  const { deleteUser, currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  if (currentUser?._id === user?._id) return null;
  const handleClick = async (event) => {
    event.preventDefault();
    await deleteUser({ _id: user._id }).then(() => navigate("/users"));
  };
  return <DefaultButton label="Delete" onClick={handleClick} />;
};

export default UserDeleteButton;
