import "./po-table-row.styles.scss";
import { Link } from "react-router-dom";
import { monthDayYear } from "../../utils/date/month-day-year.utils";
import { formatToUSD } from "../../utils/conversion/string-to-usd.conversion";
import POCell from "../po-cell/po-cell.component";
import { useContext } from "react";
import { CustomerContext } from "../../contexts/customer.context";

const POTableRow = ({ invoice, invoiceIndex, poDate }) => {
  const { customer } = useContext(CustomerContext);
  const { line_items } = invoice;

  const highlight = () => {
    if (new Date(invoice.due_date) <= new Date(poDate)) return "highlight-red";
    if (invoiceIndex % 2 === 0) return "highlight-green";
    return "";
  };

  return line_items.map((lineItem, index) => (
    <div className={`po-row ${highlight()}`} key={lineItem._id}>
      <POCell label="Batch #">{index === 0 ? invoice.po_number : ""}</POCell>
      <POCell label="Invoice #">
        {index === 0 ? (
          <Link
            to={`https://techcyclesolutions.repairshopr.com/my_profile/v2/invoices/${invoice.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {invoice.number}
          </Link>
        ) : (
          ""
        )}
      </POCell>
      <POCell label="Ticket #">
        {index === 0 ? invoice.ticket_number : ""}
      </POCell>
      {customer?.id === 29009491 && (
        <POCell label="Device Type">
          {index === 0 ? invoice?.user_type : ""}
        </POCell>
      )}
      <POCell label="Invoice Date">
        {index === 0 ? monthDayYear(+invoice.date) : ""}
      </POCell>
      <POCell label="Due Date">
        {index === 0 ? monthDayYear(new Date(invoice.due_date)) : ""}
      </POCell>
      <POCell label="Line Item">{lineItem.item}</POCell>
      <POCell label="Quantity">{lineItem.quantity}</POCell>
      <POCell label="Line Total">{formatToUSD(lineItem.price)}</POCell>
    </div>
  ));
};

export default POTableRow;
