import "./reset-password-modal.styles.scss";
import Modal from "react-bootstrap/Modal";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts/user.context";
import FormInput from "../../form-input/form-input.component";
import Button from "../../button/button.component";
import { MessagesContext } from "../../../contexts/messages.context";

const defaultFormFields = {
  email: "",
};

const ResetPasswordModal = ({ show, setShow }) => {
  const { addMessage } = useContext(MessagesContext);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email } = formFields;
  const { sendPasswordResetEmail } = useContext(UserContext);

  const resetModal = () => {
    setFormFields(defaultFormFields);
  };

  useEffect(() => {
    if (!show) resetModal();
  }, [show]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email === "") throw new Error("Please enter your email");
      const response = await sendPasswordResetEmail(email);
      if (!response || response?.errors) throw new Error(response.errors);
      addMessage("Email sent", "success");
      setShow(false);
    } catch ({ error, errors }) {
      addMessage(error?.message, "error");
    }
  };
  return (
    <Modal show={show} onHide={() => setShow(false)} size="md" centered>
      <Modal.Header closeButton>Reset Password</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="change-password-modal">
          <div>
            <FormInput
              label="Email"
              type="email"
              name="email"
              value={email}
              onChange={onChangeHandler}
            />
          </div>
          <div className="submit-btn">
            <Button label="Submit" type="submit" onClick={handleSubmit} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;
