import { gql } from "@apollo/client";

export const GET_TICKET_QUERY = gql`
  query GetTicket($_id: String, $id: Int) {
    ticket(_id: $_id, id: $id) {
      _id
      id
      number
      subject
      created_at
      customer_business_then_name
      updated_at
      received_at
      resolved_at
      problem_type
      status_changes {
        date
        old_status
        new_status
      }
      status
      comments {
        updated_at
        body
        subject
        hidden
        id
      }
      assets {
        asset_serial
      }
      contact_name
    }
  }
`;

export const GET_TICKETS = gql`
  query GetTickets(
    $customer_id: Int
    $contact_id: Int
    $status: String
    $sort: String
    $filter: [String]
  ) {
    tickets(
      customer_id: $customer_id
      contact_id: $contact_id
      status: $status
      sort: $sort
      filter: $filter
    ) {
      _id
      number
      subject
      created_at
      customer_business_then_name
      updated_at
      received_at
      resolved_at
      problem_type
      status
      comments {
        updated_at
        body
        hidden
        id
      }
      assets {
        asset_serial
      }
      contact_name
      contact {
        name
      }
    }
  }
`;

export const TICKET_COUNT = gql`
  query TicketCount($customer_id: Int!, $status: String) {
    ticketCount(customer_id: $customer_id, status: $status) {
      count
    }
  }
`;

export const SEARCH_TICKETS = gql`
  query SearchTickets(
    $search: String!
    $customer_id: Int
    $customer_ids: [Int]
  ) {
    ticketSearch(
      search: $search
      customer_id: $customer_id
      customer_ids: $customer_ids
    ) {
      _id
      number
      subject
      created_at
      updated_at
      problem_type
      status
    }
  }
`;

export const TICKET_ASSET_SEARCH = gql`
  query TicketAssetSearch($customer_id: Int!, $asset_ids: [Int]) {
    ticketAssetSearch(customer_id: $customer_id, asset_ids: $asset_ids) {
      _id
      number
      status
    }
  }
`;
