import { gql } from "@apollo/client";

export const GET_INVOICES_QUERY = gql`
  query GetInvoices($contact_id: Int, $sort: String, $customer_id: Int) {
    invoices(contact_id: $contact_id, sort: $sort, customer_id: $customer_id) {
      _id
      id
      number
      verified_paid
      is_paid
      balance_due
      customer_id
      customer_fullname
      customer_business_name
      created_at
      updated_at
      due_date
      subtotal
      total
      tax
      ticket_id
      hardwarecost
      po_number
      line_items {
        id
        created_at
        item
        name
        cost
        price
        quantity
        product_id
        discount_percent
        product_category
        line_discount_percent
        discount_dollars
      }
      ticket {
        number
      }
    }
  }
`;

export const GET_INVOICE_QUERY = gql`
  query GetInvoice($_id: String!) {
    invoice(_id: $_id) {
      _id
      id
      number
      verified_paid
      is_paid
      balance_due
      customer_id
      customer_fullname
      customer_business_name
      created_at
      updated_at
      due_date
      subtotal
      total
      tax
      ticket_id
      hardwarecost
      po_number
      line_items {
        id
        created_at
        item
        name
        cost
        price
        quantity
        product_id
        discount_percent
        product_category
        line_discount_percent
        discount_dollars
      }
    }
  }
`;
