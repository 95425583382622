import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./user.context";
import { MessagesContext } from "./messages.context";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PURCHASE_ORDER_PDF } from "../api/mutations/purchase-order.mutations";
import {
  GET_POS_QUERY,
  GET_PURCHASE_ORDER_QUERY,
} from "../api/queries/pos.queries";

const downloadPDF = (base64PDF, number = "") => {
  const byteCharacters = atob(base64PDF);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `TCS PO - ${number}`;
  link.click();
};

export const POsContext = createContext({
  pos: [],
  setPos: () => null,
  fetchPOs: () => null,
  fetchPurchaseOrder: () => null,
  downloadPurchaseOrderPDF: () => null,
});

export const PosProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(UserContext);
  const [pos, setPos] = useState([]);
  const [getPurchaseOrderPDF] = useMutation(GET_PURCHASE_ORDER_PDF);
  const [getPurchaseOrder] = useLazyQuery(GET_PURCHASE_ORDER_QUERY);
  const [getPos, { data: getPosData }] = useLazyQuery(GET_POS_QUERY);

  useEffect(() => {
    if (getPosData?.pos.length > 0) {
      setPos(getPosData.pos);
    }
  }, [getPosData]);

  const fetchPOs = async (sort, direction) => {
    try {
      if (!currentUser) return;
      const response = await getPos({
        variables: {
          customer_id: currentUser.customer_id,
          sort: `${direction === "asc" ? "" : "-"}${sort}`,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const fetchPurchaseOrder = async (_id) => {
    try {
      if (!currentUser) throw new Error("No current user");
      if (!_id) throw new Error("No purchase order id");
      const response = await getPurchaseOrder({
        variables: {
          _id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      return response?.data?.purchaseOrder;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const downloadPurchaseOrderPDF = async (_id, number) => {
    try {
      if (!currentUser || !_id) throw new Error("No user or purchase order id");
      const response = await getPurchaseOrderPDF({
        variables: {
          _id,
        },
      });
      if (!response || response?.error) throw new Error(response.error);
      downloadPDF(response?.data?.purchaseOrderPDF, number);
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    pos,
    setPos,
    fetchPOs,
    fetchPurchaseOrder,
    downloadPurchaseOrderPDF,
  };
  return <POsContext.Provider value={value}>{children}</POsContext.Provider>;
};
