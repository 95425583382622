import "./shipping-label.styles.scss";
import DefaultButton from "../../default/default.button.component";
import { useContext } from "react";
import { ShippingLabelsContext } from "../../../../contexts/shipping-labels.context";

const ShippingLabelCreateButton = ({ addLabel }) => {
  const { createShippingLabel } = useContext(ShippingLabelsContext);
  const handleClick = async () => {
    await createShippingLabel().then((res) =>
      res?._id ? addLabel(res) : null
    );
  };
  return <DefaultButton label="New Label" onClick={handleClick} />;
};

export default ShippingLabelCreateButton;
