import "./pos.styles.scss";
import Filter from "../../components/filter/filter.component";
import LargeTable from "../../components/large-table/large-table.component";
import { useState, useContext, useEffect } from "react";
import { POsContext } from "../../contexts/pos.context";

const defaultFormFields = {
  sort: "po_number",
  direction: "desc",
};

const poFields = [
  { label: "Created", value: "po_date" },
  { label: "Balance Due", value: "balance_due", format: "currency" },
];

const POs = () => {
  const { pos, fetchPOs } = useContext(POsContext);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { sort, direction } = formFields;
  const [results, setResults] = useState("");

  useEffect(() => {
    fetchPOs(sort, direction);
  }, [sort, direction]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };
  return (
    <div className="pos index-container">
      <Filter
        sortType="po"
        handleChange={handleChange}
        formFields={formFields}
        searchData={pos}
        results={setResults}
      />
      <LargeTable
        array={results ? results : pos}
        tableName="Purchase Orders"
        keyValue="_id"
        label="po_number"
        fields={poFields}
      />
    </div>
  );
};

export default POs;
