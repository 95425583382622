import { useState, useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import ResetPasswordModal from "../modals/reset-password/reset-password-modal.component";
import "./login-form.styles.scss";
import Button from "react-bootstrap/Button";

const defaultFormFields = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { email, password } = formFields;
  const { login, error, setError } = useContext(UserContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleReset = () => {
    setShowPasswordModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // If error, reset error before next login attempt
    if (error) setError(null);
    // Submit form to backend for verification
    await login(email, password);
  };

  return (
    <>
      <ResetPasswordModal
        show={showPasswordModal}
        setShow={setShowPasswordModal}
      />
      <div className="form-container tcs-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-fields">
            <div className="login-field">
              <label className="login-field-label" htmlFor="email">
                Email
              </label>
              <input
                className="login-field-input"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="login-field">
              <label className="login-field-label" htmlFor="password">
                Password
              </label>
              <input
                className="login-field-input"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>
          </div>

          <Button className="login-btn" variant="primary" type="submit">
            Login
          </Button>
          <div className="reset-btn" onClick={handleReset}>
            Forgot Password?
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
