import "./asset.styles.scss";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AssetsContext } from "../../contexts/assets.context";
import CreateTicketNavigateButton from "../../components/buttons/navigate/create-ticket/create-ticket.navigate.button.component";

const Asset = () => {
  const { fetchAsset } = useContext(AssetsContext);
  const [asset, setAsset] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchAssetData = async () => {
      const assetData = await fetchAsset(id);
      setAsset(assetData);
    };
    fetchAssetData();
  }, []);
  if (!asset) return null;
  return (
    <div className="asset index-container">
      <div className="header">
        <h2>{asset.asset_serial}</h2>
      </div>
      <div className="btn-options">
        <CreateTicketNavigateButton asset={asset} />
      </div>
      <div className="asset-content tcs-container">
        <div className="asset-card">
          <div className="card-header">Asset</div>
          <div className="row">{asset.make}</div>
          <div className="row">{asset.model}</div>
          <div className="row">{asset.asset_type}</div>
        </div>
        <div className="warranty-card">
          <div className="card-header">Warranty</div>
          <div className="row">{asset?.warranty_type || "None"}</div>
          {asset?.warranty && (
            <>
              {asset?.warranty_start_date && (
                <div className="row">{asset?.warranty_start_date}</div>
              )}
              {asset?.warranty_end_date && (
                <div className="row">{asset?.warranty_end_date}</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Asset;
