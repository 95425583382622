import "./po-header-info.styles.scss";
import POHeaderRow from "../po-header-row/po-header-row.component";

import { formatToUSD } from "../../utils/conversion/string-to-usd.conversion";

const POHeaderInfo = ({ purchaseOrder, showDiscount = true }) => {
  if (!purchaseOrder) return null;
  return (
    <div className="po-header-info">
      <POHeaderRow label="PO Date:" value={purchaseOrder.po_date} />
      <POHeaderRow label="PO #" value={purchaseOrder.po_number} />
      {showDiscount && (
        <POHeaderRow
          label="Discount Total:"
          value={formatToUSD(purchaseOrder.discount_total)}
        />
      )}
      <POHeaderRow
        label="Balance Due:"
        value={formatToUSD(purchaseOrder.balance_due)}
      />
    </div>
  );
};

export default POHeaderInfo;
