import "./estimate.styles.scss";
import PerViewContainer from "../../components/per-view/container/per-view-container.component";
import { useState, useContext, useEffect } from "react";
import { EstimatesContext } from "../../contexts/estimates.context";
import { useParams } from "react-router-dom";
import LineItemsCard from "../../components/cards/line-items/line-items-card.component";
import EstimateStatusSelectFormField from "../../components/form-fields/select/estimate-status/estimate-status.select.form-field.component";

const defaultFormFields = {
  status: "",
};

const Estimate = () => {
  const { id } = useParams();
  const [estimate, setEstimate] = useState(null);
  const { fetchEstimate, updateEstimate } = useContext(EstimatesContext);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { status } = formFields;

  useEffect(() => {
    fetchEstimate({ _id: id }).then((res) => setEstimate(res));
  }, [id]);

  useEffect(() => {
    if (!status) return;
    const input = {
      _id: id,
      status,
    };
    updateEstimate(input).then((res) => setEstimate(res));
  }, [status]);

  if (!estimate) return null;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };
  return (
    <PerViewContainer>
      <div className="estimate-header">
        <h2>{estimate.number} - </h2>
        <EstimateStatusSelectFormField
          status={estimate.status}
          handleChange={handleChange}
        />
      </div>

      <LineItemsCard invoice={estimate} />
    </PerViewContainer>
  );
};

export default Estimate;
