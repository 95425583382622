import "./csv-uploader.styles.scss";
import { useMutation } from "@apollo/client";
import { UPLOAD_ASSETS } from "../../api/mutations/asset.mutations";
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { ReactComponent as UploadImg } from "../../assets/icons/upload.svg";

function CSVUploader() {
  const [data, setData] = useState([]);
  const [uploadAssets] = useMutation(UPLOAD_ASSETS);

  useEffect(() => {
    if (data.length > 0) uploadAssets({ variables: { assets: data } });
  }, [data]);

  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const indices = [
      headers.indexOf("Serial Number"),
      headers.indexOf("Make"),
      headers.indexOf("Model"),
      headers.indexOf("Asset Type"),
      headers.indexOf("(Optional)Service Tag"),
    ];

    // Verify that all headers are present
    if (indices.includes(-1)) {
      throw new Error(
        "Invalid CSV file: missing one or more required headers."
      );
    }

    const newData = rows.map((row) => {
      const values = row.split(delim);
      return {
        asset_serial: values[indices[0]],
        make: values[indices[1]],
        model: values[indices[2]],
        asset_type: values[indices[3]],
        service_tag: values[indices[4]],
      };
    });

    setData(newData);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      complete: function (results) {
        try {
          processCSV(results.data.join("\n"));
        } catch (e) {
          alert(e);
        }
      },
    });
  };

  return (
    <div className="csv-uploader">
      <input
        type="file"
        id="csvFileUpload"
        accept=".csv"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <label htmlFor="csvFileUpload" style={{ cursor: "pointer" }}>
        {<UploadImg />}Upload CSV
      </label>
    </div>
  );
}

export default CSVUploader;
