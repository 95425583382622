import "./user.styles.scss";
import DefaultButton from "../../default/default.button.component";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/user.context";
import { MessagesContext } from "../../../../contexts/messages.context";

const defaultUserForm = {
  name: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  role: "",
  password: "",
};

const UserCreateButton = ({ userForm, setUserForm, requiredFields }) => {
  const { createUser } = useContext(UserContext);
  const { addMessage } = useContext(MessagesContext);
  const handleCreate = async (event) => {
    event.preventDefault();
    // Check for required fields
    for (const field of requiredFields) {
      if (!userForm[field]) {
        addMessage(`Please enter a value for ${field}`, "error");
        return;
      }
    }
    const response = await createUser(userForm);
    if (response) {
      setUserForm(defaultUserForm);
    }
  };
  return <DefaultButton label="Create" onClick={handleCreate} />;
};

export default UserCreateButton;
