import { createContext, useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MessagesContext } from "./messages.context";

import {
  DELETE_CONTACT_MUTATION,
  LOGIN_CONTACT_MUTATION,
  UPDATE_CONTACT_MUTATION,
  RESET_PASSWORD_MUTATION,
  CREATE_CONTACT_MUTATION,
} from "../api/mutations/contact.mutations";
import { useNavigate, useLocation } from "react-router-dom";
import {
  VERIFY_CONTACT_QUERY,
  CONTACTS_QUERY,
  CONTACT_QUERY,
} from "../api/queries/contact.queries";

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
  validCredentials: null,
  setValidCredentials: () => null,
  updateUser: () => null,
  sendPasswordResetEmail: () => null,
  verifyUser: () => null,
  createUser: () => null,
  deleteUser: () => null,
  refetchUsers: () => null,
  login: () => null,
  logout: () => null,
});

export const UserProvider = ({ children }) => {
  const location = useLocation();
  const { addMessage } = useContext(MessagesContext);
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(false);
  const [validCredentials, setValidCredentials] = useState(false);
  const [resetPasswordEmail] = useMutation(RESET_PASSWORD_MUTATION);
  const [updateUserMutation] = useMutation(UPDATE_CONTACT_MUTATION);
  const [createUserMutation] = useMutation(CREATE_CONTACT_MUTATION);
  const [deleteUserMutation] = useMutation(DELETE_CONTACT_MUTATION);
  const [getUsersQuery, { refetch: refetchUsers }] =
    useLazyQuery(CONTACTS_QUERY);
  const [getUserQuery] = useLazyQuery(CONTACT_QUERY);
  const [verifyUserQuery] = useLazyQuery(VERIFY_CONTACT_QUERY, {
    variables: { token },
    fetchPolicy: "network-only",
  });
  const [loginMutation] = useMutation(LOGIN_CONTACT_MUTATION);

  useEffect(() => {
    verifyUser();
  }, []);

  useEffect(() => {
    if (currentUser && location.pathname === "/login") navigate("/tickets");
  });

  const verifyUser = async () => {
    try {
      if (!token) return navigate("/login");
      const response = await verifyUserQuery();
      if (!response || response?.error) {
        sessionStorage.clear();
        navigate("/login");
        return;
      }
      setCurrentUser(response.data.verifyContact);
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const updateUser = async ({ ...updatedFields }) => {
    const { address1, address2, city, state, zip, password, name, _id, role } =
      updatedFields;
    try {
      const updatedUser = await updateUserMutation({
        variables: {
          _id,
          address1,
          address2,
          city,
          state,
          zip,
          password,
          name,
          role,
        },
        fetchPolicy: "network-only",
      });
      if (!updatedUser?.data?.updateContact)
        throw new Error("Failed to update User");
      const newUserData = updatedUser.data.updateContact;
      if (newUserData?._id === currentUser._id) setCurrentUser(newUserData);
      addMessage("User Updated", "success");
      return newUserData;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const login = async (email, password) => {
    try {
      if (!email || !password) throw new Error("Missing Credentials");
      const response = await loginMutation({
        variables: {
          email,
          password,
        },
      });
      if (!response) throw new Error("Failed to login");
      if (response.errors) console.log({ response });
      if (response.errors) throw new Error(response.errors[0].message);
      setCurrentUser(response.data.loginContact.contact);
      const token = response.data.loginContact.token;
      sessionStorage.setItem("token", token);
      navigate("/tickets");
      return;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const logout = () => {
    // logoutQuery();
    setCurrentUser(null);
    sessionStorage.clear();
    setValidCredentials(false);
    navigate("/login");
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      const response = await resetPasswordEmail({
        variables: {
          email,
        },
      });
      if (!response || response?.errors)
        throw new Error("Failed to send password reset email");
      addMessage("Password Reset Email Sent", "success");
      return response.data.emailPasswordReset;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const isAuthenticated = () => {
    return !!currentUser;
  };

  const getUsers = async (sort, direction) => {
    try {
      const response = await getUsersQuery({
        variables: {
          customer_id: currentUser.customer_id,
          sort: `${direction === "asc" ? "" : "-"}${sort}`,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.errors) throw new Error("Failed to get users");
      return response.data.contacts;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const getUser = async (id) => {
    try {
      const response = await getUserQuery({
        variables: { _id: id, customer_id: currentUser.customer_id },
        fetchPolicy: "network-only",
      });
      if (!response || response?.errors) throw new Error("Failed to get user");
      return response.data.getContact;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const createUser = async ({ ...newUser }) => {
    try {
      const response = await createUserMutation({
        variables: {
          ...newUser,
          customer_id: currentUser.customer_id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.errors)
        throw new Error("Failed to create user");
      addMessage("User Created", "success");
      return response.data.createContact;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const deleteUser = async ({ _id }) => {
    try {
      const response = await deleteUserMutation({
        variables: {
          _id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.errors)
        throw new Error("Failed to delete user");
      addMessage("User Deleted", "success");
      return response.data.deleteContact;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    currentUser,
    setCurrentUser,
    login,
    logout,
    isAuthenticated,
    validCredentials,
    updateUser,
    sendPasswordResetEmail,
    verifyUser,
    getUsers,
    getUser,
    createUser,
    deleteUser,
    refetchUsers,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
