const searchTickets = async (timedSearch, data, results) => {
  if (timedSearch) {
    let response = data.filter((item) => {
      const foundResults = Object.entries(item).filter(([key, value]) => {
        if (typeof value === "number") value = value.toString();
        if (typeof value === "string") {
          const match = value
            .toLowerCase()
            .startsWith(timedSearch.toLowerCase());
          return match ? match : false;
        }
        return false;
      });
      return foundResults.length ? foundResults : false;
    });
    if (!response.length) {
      response = data.filter((item) =>
        item?.assets[0]?.asset_serial
          .toLowerCase()
          .startsWith(timedSearch.toLowerCase())
      );
    }
    results(response);
  }
};

module.exports = { searchTickets };
