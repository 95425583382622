import { gql } from "@apollo/client";

export const GET_ASSETS = gql`
  query GetAssets(
    $customer_id: Int
    $contact_id: Int
    $asset_serial: String
    $sort: String
  ) {
    getAssets(
      customer_id: $customer_id
      contact_id: $contact_id
      asset_serial: $asset_serial
      sort: $sort
    ) {
      _id
      id
      customer_id
      contact_id
      name
      created_at
      updated_at
      asset_serial
      asset_type
      asset_type_id
      make
      model
      service_tag
      warranty
      warranty_type
      warranty_end_date
      warranty_start_date
    }
  }
`;

export const GET_ASSET = gql`
  query GetAsset($_id: String!) {
    asset(_id: $_id) {
      _id
      id
      customer_id
      contact_id
      name
      created_at
      updated_at
      asset_serial
      asset_type
      asset_type_id
      make
      model
      service_tag
      warranty
      warranty_type
      warranty_end_date
      warranty_start_date
    }
  }
`;
