import LargeTable from "../../components/large-table/large-table.component";
import "./shipping-labels.styles.scss";
import { useState, useContext, useEffect } from "react";
import { ShippingLabelsContext } from "../../contexts/shipping-labels.context";
import Filter from "../../components/filter/filter.component";
import ShippingLabelCreateButton from "../../components/buttons/create/shipping-label/shipping-label.create.button.component";

const defaultFormFields = {
  sort: "created_at",
  direction: "desc",
};

const shippingLabelFields = [{ label: "Created", value: "created_at" }];

const ShippingLabels = () => {
  const [shippingLabels, setShippingLabels] = useState([]);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { sort, direction } = formFields;
  const [results, setResults] = useState("");
  const { fetchShippingLabels } = useContext(ShippingLabelsContext);

  useEffect(() => {
    fetchShippingLabels(sort, direction).then((response) => {
      setShippingLabels(response);
    });
  }, [sort, direction]);
  if (!shippingLabels) return null;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };
  const insertNewLabel = async (newLabel) => {
    const response = await fetchShippingLabels(sort, direction, null, true);
    setShippingLabels(response);
  };
  return (
    <div className="shipping-labels index-container">
      <ShippingLabelCreateButton addLabel={insertNewLabel} />
      <Filter
        sortType={"shippingLabel"}
        handleChange={handleChange}
        formFields={formFields}
        searchData={shippingLabels}
        results={setResults}
      />
      <LargeTable
        tableName="Shipping Labels"
        array={results ? results : shippingLabels}
        label="tracking_number"
        fields={shippingLabelFields}
      />
    </div>
  );
};

export default ShippingLabels;
