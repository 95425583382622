import "./ticket-csv-uploader.styles.scss";
import { useMutation } from "@apollo/client";
import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import { UserContext } from "../../contexts/user.context.jsx";
import { MessagesContext } from "../../contexts/messages.context.jsx";
import { BATCH_UPLOAD_PENDING_TICKETS } from "../../api/mutations/pending-tickets.mutations";
import Loader from "../loader/loader.component.jsx";

function TicketCSVUploader() {
  const { currentUser } = useContext(UserContext);
  const { addMessage } = useContext(MessagesContext);
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadTickets] = useMutation(BATCH_UPLOAD_PENDING_TICKETS);

  const uploadFile = async (data) => {
    setUploading(true); 
    try {
      const response = await uploadTickets({ variables: { input: data } });
      if (!response || response?.errors) {
        return addMessage(response.errors[0].message, "error");
      }
      const ticketsCreated = response.data.batchUploadPendingTickets.length;
      addMessage(`${ticketsCreated} Tickets uploaded successfully.`, "success");
    } catch (error) {
      return addMessage(error.message, "error");
    }finally {
      setUploading(false); 
    }
  };

  useEffect(() => {
    if (data.length > 0) uploadFile(data);
  }, [data]);

  const processCSV = (data, delim = ",") => {
    if (!!data.lenght) alert("Invalid CSV file: no data found.");
    // Remove commas from within fields
    const removeCommas = data.map((row) => {
      return row.map((col) => {
        if (col.includes(",")) return col?.replace(/,/g, ";");
        return col;
      });
    });
    // Rejoin the rows with the delimiter
    const str = removeCommas.map((row) => row.join(delim)).join("\n");

    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const indices = [
      headers.indexOf("Serial Number"),
      headers.indexOf("Make"),
      headers.indexOf("Model"),
      headers.indexOf("Asset Type"),
      headers.indexOf("(Optional)Service Tag"),
      headers.indexOf("Problem Type"),
      headers.indexOf("Comment"),
      headers.indexOf("(Optional)Tracking Number"),
    ];

    // Verify that all headers are present
    if (indices.includes(-1)) {
      throw new Error(
        "Invalid CSV file: missing one or more required headers."
      );
    }

    const newData = rows.map((row) => {
      const values = row.split(delim);
      return {
        asset_serial: values[indices[0]],
        make: values[indices[1]],
        model: values[indices[2]],
        asset_type: values[indices[3]],
        service_tag: values[indices[4]],
        problem_type: values[indices[5]],
        comment: values[indices[6]],
        tracking_number: values[indices[7]]?.replace(/ /g, ""), // Remove spaces from tracking number
        customer_id: currentUser.customer_id,
        contact_id: currentUser.id,
      };
    });
    setData(newData);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      complete: function (results) {
        try {
          // processCSV(results.data.join("\n"));
          processCSV(results.data);
        } catch (e) {
          alert(e);
        }
      },
    });
    e.target.value = null;
  };

  return (
    <div className="ticket-csv-uploader">

      {uploading && (
        <Loader/>
      )}

      <input
        type="file"
        id="csvFileUpload"
        accept=".csv"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <label
        htmlFor="csvFileUpload"
        style={{
          cursor: uploading ? "not-allowed" : "pointer",
          backgroundColor: uploading ? "grey" : "",
          opacity: uploading ? 0.5 : 1,
        }}
      >
        {uploading ? "Uploading..." : "Upload Tickets"}
      </label>
    </div>
  );
}

export default TicketCSVUploader;
