// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.po-table .col-headers {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.po-table .col-headers .col-header {
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #8dc63f;
}
.po-table .body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  row-gap: 10px;
}
.po-table .body .highlight-green {
  background: rgba(141, 198, 63, 0.1);
}
.po-table .body .highlight-red {
  background: rgba(255, 0, 0, 0.1);
}

@media screen and (max-width: 500px) {
  .col-headers {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/po-table/po-table.styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACE,aAAA;EACA,SAAA;EACA,uBAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;AAFJ;AAII;EACE,mCAAA;AAFN;AAKI;EACE,gCAAA;AAHN;;AAQA;EACE;IACE,wBAAA;EALF;AACF","sourcesContent":[".po-table {\n  .col-headers {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n\n    .col-header {\n      display: flex;\n      flex: 1;\n      justify-content: center;\n      padding: 10px;\n      font-weight: bold;\n      font-size: 14px;\n      color: #8dc63f;\n    }\n  }\n\n  .body {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    justify-content: center;\n    row-gap: 10px;\n\n    .highlight-green {\n      background: rgba(141, 198, 63, 0.1);\n    }\n\n    .highlight-red {\n      background: rgba(255, 0, 0, 0.1);\n    }\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .col-headers {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
