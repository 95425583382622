// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.po-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 500px) {
  .po-row {
    flex-wrap: wrap;
    gap: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/po-table-row/po-table-row.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,iDAAA;AAAF;;AAGA;EACE;IACE,eAAA;IACA,QAAA;EAAF;AACF","sourcesContent":[".po-row {\n  display: flex;\n  flex-direction: row;\n\n  width: 100%;\n  border: none;\n  border-radius: 5px;\n  padding: 5px;\n  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);\n}\n\n@media screen and (max-width: 500px) {\n  .po-row {\n    flex-wrap: wrap;\n    gap: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
