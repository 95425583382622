import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./user.context";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_COMMENT_MUTATION } from "../api/mutations/ticket.mutations";
import { GET_TICKETS, GET_TICKET_QUERY } from "../api/queries/ticket.queries";
import { MessagesContext } from "./messages.context";

export const TicketsContext = createContext({
  tickets: [],
  setTickets: () => null,
  fetchTickets: () => null,
  fetchTicket: () => null,
  ticketsLoading: false,
  setTicketsLoading: () => null,
  addComment: () => null,
});

export const TicketsProvider = ({ children }) => {
  const { addMessage } = useContext(MessagesContext);
  const { currentUser } = useContext(UserContext);
  const [tickets, setTickets] = useState([]);
  const [ticketsLoading, setTicketsLoading] = useState(false);
  const [addCommentMutation] = useMutation(ADD_COMMENT_MUTATION);
  const [getTicket] = useLazyQuery(GET_TICKET_QUERY);
  const [getTickets, { loading, data: getTicketsData }] =
    useLazyQuery(GET_TICKETS);

  useEffect(() => {
    if (loading) {
      setTicketsLoading(true);
    } else {
      setTicketsLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (getTicketsData?.tickets.length > 0) {
      setTickets(getTicketsData.tickets);
    }
  }, [getTicketsData]);

  const fetchTickets = async (sort, direction, filter) => {
    try {
      if (!currentUser) return;
      const response = await getTickets({
        variables: {
          customer_id: currentUser.customer_id,
          contact_id: currentUser.role === "admin" ? null : currentUser.id,
          sort: `${direction === "asc" ? "" : "-"}${sort}`,
          filter,
        },
        fetchPolicy: "no-cache",
      });
      if (!response || response?.error) throw new Error(response.error);
      return response?.data?.tickets;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const fetchTicket = async ({ _id, id }) => {
    try {
      if (!currentUser) return;
      const response = await getTicket({
        variables: {
          _id,
          id,
        },
        fetchPolicy: "network-only",
      });
      if (!response || response?.error) throw new Error(response.error);
      return response?.data?.ticket;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const addComment = async (
    ticket_id,
    newStatus = "Customer Reply",
    body,
    subject = "Test",
    hidden = false,
    tech = "TCS Portal"
  ) => {
    try {
      if (!ticket_id || !body)
        throw new Error("Ticket ID and Comment are required");
      const response = await addCommentMutation({
        variables: {
          ticket_id,
          newStatus,
          body,
          subject,
          hidden,
          tech,
        },
      });
      if (!response || response?.errors) throw new Error(response.errors[0]);
      return response?.data?.addComment;
    } catch (error) {
      addMessage(error.message, "error");
    }
  };

  const value = {
    tickets,
    fetchTickets,
    fetchTicket,
    ticketsLoading,
    addComment,
  };
  return (
    <TicketsContext.Provider value={value}>{children}</TicketsContext.Provider>
  );
};
