// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket .header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ticket .header .redirect-btn:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
}
.ticket .header .redirect-btn:active {
  transform: scale(0.9);
}
.ticket .status-progress-bar {
  width: 100%;
  padding: 20px;
}
.ticket .ticket-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  gap: 25px;
}`, "",{"version":3,"sources":["webpack://./src/routes/ticket/ticket.styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAGM;EACE,eAAA;EACA,kBAAA;EACA,uBAAA;AADR;AAGM;EACE,qBAAA;AADR;AAME;EACE,WAAA;EACA,aAAA;AAJJ;AAOE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;AALJ","sourcesContent":[".ticket {\n  .header {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n\n    .redirect-btn {\n      &:hover {\n        cursor: pointer;\n        border-radius: 50%;\n        background-color: white;\n      }\n      &:active {\n        transform: scale(0.9);\n      }\n    }\n  }\n\n  .status-progress-bar {\n    width: 100%;\n    padding: 20px;\n  }\n\n  .ticket-body {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    flex-wrap: wrap;\n    width: 100%;\n    margin: auto;\n    gap: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
