import "./loader.styles.scss";

const Loader = () => {
  return (
    <div className="loader-overlay">
        <div className="loader">
        <div className="spinner"></div>
        <p>Processing... Please wait</p>
        </div>
    </div>
  );
};

export default Loader;
