// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assets-btn-menu {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.filter {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.filter .filter-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
.filter .filter-item select {
  width: 175px;
}`, "",{"version":3,"sources":["webpack://./src/routes/assets/assets.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACE,YAAA;AACN","sourcesContent":[".assets-btn-menu {\n  display: flex;\n  flex-direction: row;\n  column-gap: 20px;\n}\n\n.filter {\n  width: 100%;\n  padding: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n\n  .filter-item {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    column-gap: 20px;\n\n    select {\n      width: 175px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
