// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  display: flex;
  padding: 100px 0 100px 0;
}`, "",{"version":3,"sources":["webpack://./src/routes/login/login.styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,wBAAA;AACF","sourcesContent":[".login {\n  margin: auto;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  row-gap: 20px;\n  display: flex;\n  padding: 100px 0 100px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
