import "./form-text-area.styles.scss";

// PROP TYPES rows, cols, id, name, value, onChange
const FormTextArea = ({ label, ...otherProps }) => {
  return (
    <div className="form-text-area">
      <label>{label}</label>
      <textarea {...otherProps} />
    </div>
  );
};

export default FormTextArea;
