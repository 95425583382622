import { useState, useEffect } from "react";
import FormInput from "../form-input/form-input.component";
import "./search.styles.scss";
const { searchAll } = require("../../utils/search/all.search.js");

const Search = ({ data, results, type }) => {
  const [search, setSearch] = useState("");
  const [timedSearch, setTimedSearch] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimedSearch(search);
    }, 700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  useEffect(() => {
    if (!timedSearch) results("");
    const searchResults = async () =>
      await searchAll(timedSearch, data, results, type);
    searchResults();
  }, [timedSearch, data]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="search-container">
      <FormInput
        label="Search"
        type="search"
        name="search"
        autoComplete="off"
        value={search}
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;
