// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  background: rgba(142, 198, 63, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.modal-content .modal-header {
  border-bottom: none;
  font-weight: bold;
}

.change-password-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: whitesmoke;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
}
.change-password-modal .submit-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/change-password-modal/change-password-modal.styles.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,gDAAA;EACA,0BAAA;EACA,kCAAA;EACA,mBAAA;EACA,2CAAA;AACF;AACE;EACE,mBAAA;EACA,iBAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;AAAF;AAEE;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AAAJ","sourcesContent":[".modal-content {\n  background: rgba(142, 198, 63, 0.45);\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\n  backdrop-filter: blur(4px);\n  -webkit-backdrop-filter: blur(4px);\n  border-radius: 10px;\n  border: 1px solid rgba(255, 255, 255, 0.18);\n\n  .modal-header {\n    border-bottom: none;\n    font-weight: bold;\n  }\n}\n\n.change-password-modal {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  background: whitesmoke;\n  border-radius: 10px;\n  padding: 20px;\n  width: 100%;\n\n  .submit-btn {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
