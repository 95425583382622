import "./users.styles.scss";
import LargeTable from "../../components/large-table/large-table.component";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import { useState } from "react";
import Filter from "../../components/filter/filter.component";
import AddUserNavigateButton from "../../components/buttons/navigate/add-user/add-user.navigate.button.component";

const userFields = [
  { label: "Email", value: "email" },
  { label: "Role", value: "role" },
];

const defaultFormFields = {
  sort: "name",
  direction: "asc",
};

const Users = () => {
  const { getUsers, refetchUsers } = useContext(UserContext);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { sort, direction } = formFields;
  const [results, setResults] = useState("");
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getUsers(sort, direction).then((res) => setUsers(res));
  }, [sort, direction]);

  useEffect(() => {
    if (users?.length !== 0) refetchUsers().then((res) => setUsers(res));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="users index-container">
      <div className="users-btn-menu">
        <AddUserNavigateButton />
      </div>
      <Filter
        handleChange={handleChange}
        formFields={formFields}
        searchData={users}
        searchType="user"
        results={setResults}
        sortType="user"
      />
      <LargeTable
        array={results ? results : users}
        keyValue="_id"
        label="name"
        fields={userFields}
        tableName="Users"
      />
    </div>
  );
};

export default Users;
